
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        






































































































































.exit {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 80px;
  width: 100%;

  justify-content: center;
  align-items: stretch;

  .abilita {
    background-color: $white;
  }

  &.enabled {
    .abilita {
      color: $white;
      background-color: $cerulean;
      .exit-number {
        color: $white;
      }
    }

    .ricerca, .valore {
      color: $black;
      textarea { color: $black; }
    }

    &.right {
      .abilita {
        background-color: $danger;
      }
    }

    &.top {
      .abilita {
        background-color: $gold;
      }
    }
  }

  &.right {
    flex-direction: row-reverse;

    .abilita, .ricerca, .valore {
      margin: 0 0 0 5px;
    }

    .row-actions {
      .abilita {
        margin-right: 0;
        margin-left: 20px;
      }

      .row-actions-more {
        right: calc(100% - 20px);
        left: auto;
        transform: translateX(calc(100% - 12px));

        &:hover {

        }
      }
    }

    .valore {
      flex-direction: row-reverse;

      textarea {
      }
    }
  }

  &.top {
    .routes {
      opacity: 0;
    }
  }

  textarea {
    border: 0;
    color: $dark-grey;
    resize: none;
  }

  .abilita, .ricerca, .valore {
    position: relative;
    margin: 0 5px 0 0;
    border: 1px solid $light-grey;
    border-radius: 3px;
    color: $dark-grey;
  }

  .row-actions {
    position: relative;
    cursor: pointer;

    .abilita {
      width: 45px;
      margin-right: 20px;
      height: 100%;
      cursor: pointer;
      color: $light-grey;
      position: relative;
      z-index: $zindexInteraction - 1;

      .exit-number {
        display: block;
        text-align: center;
        padding-top: 5px;
      }

      .bin-icon {
        $width: 20px;
        position: absolute;
        bottom: 10px;
        left: calc(50% - #{$width} / 2);
        width: $width;
        height: auto;
      }
    }

    .row-actions-more {
      position: absolute;
      z-index: $zindexInteraction - 2;
      height: 100%;
      top: 0;
      left: calc(100% - 20px);
      width: 45px;

      transform: translateX(calc(-100% + 12px));
      transition: opacity 0.15s  ease-out, transform 0.2s ease-out;

      background-color: $light-grey;
      opacity: .6;

      border-radius: 3px;

      &.open {
        opacity: 1;
        transform: none;
      }

      .action-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        font-size: 1.5em;
        color: $red;
      }
    }
  }

  .ricerca, .valore {
    flex-grow: 1;
  }

  .valore {
    textarea {
      width: 100%;
      height: 100%;

      outline: none;
    }
  }

}
