
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        
































































































































.app-header.dsc-header {
  padding: 0 1em;
  top: 0;

  .nav-brand {
    .navbar-brand-full {
      height: 40px;
      width: auto;
    }
  }

  .navbar-toggler {
    color: $slate-grey;
  }

  .title {
    position: absolute;
    width: 50vw;
    left: 50%;
    transform: translateX(-50%);

    font-size: 1.2em;

    @include media-breakpoint-up(md) {
      font-size: 2em;
    }
  }

  .dropdown-menu.lang {
    min-width: 0;
    .dropdown-item {
      min-width: 50px;
    }
  }

  .available-locale, .current-locale {
    // text-transform: uppercase;
  }

  .nav-right {
    .nav-item {
      margin: 0 5px;
      &.notext {
        margin: 0;
        min-width: 2em;
      }
    }
  }
}
