
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        







































































































































































































.input-select {
  position: relative;

  &.disabled {
    .input-command {
      color: $light-grey;
    }
  }
  .fake-elm {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .input-current {
    padding-right: 2em;
    text-overflow: ellipsis;

    &.is-invalid {
      border-color: #f86c6b !important;
    }
  }

  .input-command {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: #23282c;

    font-size: 1.4em;
    /*background-color: white;*/

    &.opened {
      transform: rotate(180deg) translateY(50%);
    }
  }

  .input-options {
    position: absolute;
    z-index: $zindexInteraction;

    background-color: white;
    border: 1px solid #E4E7EA;
    border-top: 0;
    width: 100%;

    max-height: 250px;
    overflow-y: auto;

    &.hidden {
      display: none;
    }

    .input-option {
      padding: .5em 1em;

      &:not(.no-results) {
        cursor: pointer;

        &:hover {
          background-color: $light-grey;
        }

        &.selected {
          font-weight: bold;
          background-color: $tableRow;
        }
      }

      &.no-results {
        font-style: italic;
        color: $light-grey;
      }
    }
  }
}
