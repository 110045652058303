
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        









































































































































































































































































































































































































.anomalies-list {
  .collapse-command-wrapper {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity .2s ease-out;
    position: relative;

    &.show {
      height: auto;
      opacity: 1;
    }

    .collapse-command {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);

      padding: 0 .5em 0 .5em;
      background-color: $white;
      font-size: 1.5em;

      a {
        color: $dark-grey;
      }
    }
  }

  .advanced-filters {
    .form-inputs__wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .form-row {
        width: 100%;
        flex: 0 0 100%;

        @include media-breakpoint-up(md) {
          width: 50%;
          flex: 0 0 50%;
          margin: 0;
        }

        /deep/ label.full-width {
          width: 100%;
        }
      }
    }
  }

  /deep/ .table.vuetable {
    font-size: .9rem;
    thead th, tbody tr td {
      white-space: nowrap;
    }
  }

  .list {
    overflow-x: auto;
  }
}
