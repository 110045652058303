
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        






















.e0bde2d1-9de8-4c96-8fb0-e7f073983af2{fill: $gold;}
