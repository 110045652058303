
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        























































































































































































































































































































































































































































































































































































































































 .container-fluid.anomalies-list {
   margin-top: 3em;
   .list {
     overflow-x: auto;
   }

   .filter-label {
     margin-right: 1em;
   }

   .width-auto {
     width: auto;
     display: inline-block;
   }

   .row.list, .row.clear-filters {
     margin-top: 1em;
   }

   .card-header {
     background-color: $botticelli;
   }

   .card-body {
     overflow: visible;

     &.overflow-visible {
       overflow: visible;
     }
   }

   .anomaly {
     font-weight: bold;
     color: $orange;

     &-workcompletedfailed {

     }
   }

/*   .disabled {
     .anomaly, .vuetable-td-shortMessage, .vuetable-td-opened, .vuetable-td-closed {
       color: $dark-grey;
     }
   }*/

   .status {
     font-weight: bold;

     &-created {
       color: $navy;
     }
     &-retried {
       color: $green;
     }
     &-ignored {
       color: $red;
     }
   }

   .row-actions {
     display: flex;
     flex-direction: row;
     justify-content: center;
   }

   .vuetable thead th, .vuetable tbody tr td.singleline {
     white-space: nowrap;
   }
 }
