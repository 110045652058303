
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        









































.dsc-footer {
  border-top: 2px solid $orange;
  display: flex;
  justify-content: center;
  background-color: $navy;
  color: $white;
  height: 48px;
  box-sizing: border-box;

  .container-fluid {
    margin: 0;
  }

  .row {
    height: 100%;
  }
}
