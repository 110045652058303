
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        






















































































.status-element__caption {
  background-color: $botticelli;
  display: block;
  padding: 0.2em 1em;

  &.collapsed {
    margin-bottom: 1em;
  }
}

.status-element__items {
  margin-bottom: 1em;
}

.vuetable {
  td:first-of-type {
    @include media-breakpoint-up(md) {
      width: 1px;
      white-space: nowrap;
    }
  }
}

