
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        
































.b6f06201-ef03-4de8-b4cc-ff71d3f46958{fill: $cerulean;} // #8abbf2
.b9bd1684-a767-4356-9c0f-42d54eaef53a,.ffb98e16-d8b1-4460-93ff-8edfc6325e7b{fill:#fff;}
.b9bd1684-a767-4356-9c0f-42d54eaef53a{stroke:#fff;stroke-miterlimit:10;stroke-width:0.61px;}
.b5e9bcc6-0349-4b8f-b7c7-b09332cd160a{fill:#1a1a1a;}
