
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        



































































































.article-select {
  position: relative;

  .options-command {
    position: absolute;
    right: .2em;
    top: 50%;
    transform: translateY(-50%);

    font-size: 1.4em;
    background-color: white;

    &.opened {
      transform: rotate(180deg) translateY(50%);
    }
  }

  .article-options {
    position: absolute;
    z-index: $zindexInteraction;

    background-color: white;
    border: 1px solid #E4E7EA;
    border-top: 0;
    width: 100%;

    max-height: 250px;
    overflow-y: auto;

    &.hidden {
      display: none;
    }

    .article-option {
      padding: .5em 1em;

      &:not(.no-results) {
        cursor: pointer;

        &:hover {
          background-color: $light-grey;
        }
      }

      &.no-results {
        font-style: italic;
        color: $light-grey;
      }
    }
  }
}
