
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        














































.rows-quantity-select {
  margin-top: 1em;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}
.width-auto {
  width: auto;
  display: inline-block;
}
