
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        











































































.loading {
  margin: 3em 0;
}

.card-body {
  height: 10em;
}

.operations {
  .card-header {
    background-color: $heater;
  }
}

.logs {
  .card-header {
    background-color: $dark-grey;
  }
}

.list {
  overflow-y: scroll;
}

table {
  td {
    cursor: pointer;
  }
}

.card {
  margin-top: 1.5rem;
}

/deep/ .refresher {
  margin-bottom: .3rem;
}

