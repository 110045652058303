$black: #000000;
$white: #FFFFFF;
$orange: #F7941E;
$slate-grey: #58595B;
$dark-grey: #949699;
$light-grey: #DCDCDC;
$botticelli: #DBE7F0;
$heater: #B3C1CA;
$cerulean: #668AAF;
$navy: #064872;

$success: #4dbd74;
$secondary: #c8ced3;
$danger: #f86c6b;
$info: #63c2de;
$link: #20a8d8;
$tableRow: rgba(0, 0, 0, 0.075);
$gold: #ceb62f;
