
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        


















































.messages-wrapper {
  position: fixed;
  top: calc(55px + 1em);
  width: 100%;
  z-index: $zIndexMessages;

  @include media-breakpoint-up(md) {
    top: 1em;
  }

  .messages-list {
    width: 90%;
    margin: 0 auto;

    .alert {
      cursor: pointer;
      position: relative;

      .close-icon {
        float: right;
      }
    }
  }
}

