
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        


















































.vuetable-pagination {
  background: #f9fafb !important;
}

.pagination {
  margin-top: 1em;

  .page-item {
    &.active {
      /*text-decoration: underline;*/
      background-color: $light-grey;
      border-radius: 5px;
    }
  }
}

.page-link, .page-item {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*border-width: 1px 0 1px 0;*/
  /*border-style: solid;*/
  /*border-color: #c8ced3;*/
  /*padding: 0.5rem 0.75rem;*/
}
