@import '~@coreui/coreui/scss/coreui';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
@import "vuetable";
@import "variables";
@import "colors";
@import "font";
@import "~vue-material-design-icons/styles.css";
//@import "~vue-material/dist/vue-material.min.css";
@import "vuetable";
@import "tooltips";

html, body {
  &.fontxsmall {
    font-size: 12px;
  }
  &.fontsmall {
    font-size: 14px;
  }
  &.fontnormal {
    font-size: 16px;
  }
}

.small {
  font-size: .9rem;
}

.sidebar {
  .nav {
    .nav-item {
      .nav-link {
        white-space: nowrap;

        &.small {
          padding: .5rem .8rem .5rem 2rem;
        }
      }
    }
  }
}

.dropdown-menu {
  font-size: 1em;
}

.container-fluid {
  margin: 3rem 0 1rem 0;

  .card-header {
    background-color: $botticelli;
  }
}

.main .container-fluid {
  padding: 0 5px;
  @include media-breakpoint-up(md) {
    padding: 0 30px;
  }
}

.container {
  max-width: none !important;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  .material-design-icon {
    &.format-list-bulleted-icon {
      margin-right: 0.5rem;

      svg {
        margin-bottom: 2px;
      }
    }
  }

  .record-counter {
    display: inline-block;
    margin-left: auto;
    text-transform: capitalize;
    font-size: .8rem;
  }
}

.app-header.dsc-header .title {
  @include media-breakpoint-down(md) {
    width: 40vw;
    font-size: 1rem;
  }
}

.modal-edit {
  .tab-pane {
    height: auto; //calc(400px + 4rem);
  }
}

.modal-header {
  font-size: 1.1rem;
  font-weight: bold;
}

.CodeMirror {
  &-linenumber, pre {
    font-size: .8rem !important;
  }
}

.vuetable, .table {
  thead {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  tbody {
    td {
      @include media-breakpoint-down(md) {
        display: block;

        &:last-of-type {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.tab-content {
  border-color: $dark-grey;

  .tab-pane {
    padding: 5px;

    @include media-breakpoint-up(md) {
      padding: 1rem
    }
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: rgba(0, 0, 0, 0.03);

  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
}

/deep/ .md-30-perc {
  @include media-breakpoint-up(md) {
    width: 30%;
  }
}

/deep/ .md-10-perc {
  @include media-breakpoint-up(md) {
    width: 10%;
  }
}

.nav-tabs {
  .nav-link {
    &.active {
      font-weight: bold;
      border-color: $dark-grey;
      border-bottom-color: #ffffff;

      &:focus {
        border-color: $dark-grey;
        border-bottom-color: #ffffff;
      }

      &:hover {
        border-bottom-color: #ffffff;
      }

      .v-popover {
        display: inline;
      }
    }

    &:hover {
      border-bottom-color: $dark-grey;
    }

    .v-popover {
      display: none;
    }
  }
  .nav-item {
    &.has-invalid-input {
      .nav-link {
        color: red;

        &.active {
          color: red;
        }
      }
    }
  }
}

/deep/ .text-error {
  color: red;
}

/deep/ textarea { resize: none; }

/deep/ .nopadding {
  padding: 0;
}

.tooltip .tooltip-inner {
  text-align: left;

  h4 {
    text-align: center;
  }
}