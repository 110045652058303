
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        










































.v-popover {
  line-height: 1rem;
  display: inline;

  .inline-help-icon {
    color: $slate-grey;
    cursor: pointer;

    &:hover {
      color: $black;
    }

    &.small {
      font-size: .8rem;
      margin-bottom: .1rem;
    }

    &.material-design-icon svg {
      margin-bottom: 0!important;
    }
  }

  &.text-white {
    .inline-help-icon {
      color: $white;
    }
  }
}
