
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        












































































































button {
  &.btn {
    color: white;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

    display: flex;
    align-items: center;
  }

  .btn-label {
    position: relative;
    left: -.75rem;
    background: rgba(0, 0, 0, 0.15);
    color: white;
    /*line-height: 100%;*/

    &.action-icon {
      font-size: 1.5em;
      padding: 0 .3em;
    }
  }

  .transparent {
    opacity: 0;
  }
}
