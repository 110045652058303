
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        


































































































.input-multi-toggle {
  border-color: transparent;
  width: auto;
  margin-bottom: 0;

  .options-wrapper {
    display: flex;
    flex-direction: row;

    .option {
      padding: .3rem .9rem;
      border: 1px solid;
      border-right: 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:first-of-type {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }

      &:last-of-type {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        border-right: 1px solid;
      }

      &.selected {
        background-color: $botticelli;
      }
    }
  }
}
