
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        































.ea0728b1-e197-4540-a62b-ac3220bc8564{fill: $danger;} //#df383e
.acf7dd58-c195-4234-b9dc-2a66d42ba175,.b90bfd3f-03ff-4756-aeec-a70f69520acf{fill:#fff;}
.b90bfd3f-03ff-4756-aeec-a70f69520acf{stroke:#fff;stroke-miterlimit:10;stroke-width:0.61px;}.a17258e8-9601-461d-93bd-ff6238654f82{fill:#1a1a1a;}
