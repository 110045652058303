
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        





































































































































































































































































































































































.container-fluid.services-list {

  .width-auto {
    width: auto;
    display: inline-block;
  }

  .row.list, .row.clear-filters {
    margin-top: 1em;
  }

  .card-header {
    background-color: $botticelli;
  }

  .card-body {
    overflow: visible;

    &.overflow-visible {
      overflow: visible;
    }
  }
}
