
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        































.f0735a18-87a8-4a8a-9887-92778ce5ff64{fill: $gold;}
.a5bcbac9-9a8f-4f8d-9907-66f56b964edf,.e4180526-8676-4416-b13f-3aadec232d72{fill:#fff;}
.a5bcbac9-9a8f-4f8d-9907-66f56b964edf{stroke:#fff;stroke-miterlimit:10;stroke-width:0.61px;}.abbc7fc1-5b9d-4a86-8bd9-8cbe985ff98b{fill:#1a1a1a;}
