
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        





















































.sidebar {
  // min-height: calc(100vh - 55px);
  background-color: $slate-grey;

  margin-left: -100%;

  .sidebar-lg-show &, .sidebar-show &, .sidebar-sm-show & {
    margin-left: 0;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 0 !important;
  }

  .nav {
    .nav-item {
      .nav-dropdown-items {
        .nav-link {
          padding-left: 2em;
        }
      }

      .nav-link {
        text-transform: capitalize;

        &:hover {
          background-color: $navy;
        }

        &.nav-dropdown-toggle {
          /*text-transform: uppercase;*/
          /*font-weight: bold;*/
        }

        &.router-link-active {
          color: $white;
          background-color: $cerulean;
        }
      }
    }
  }
}
