
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        











































































































































































.settings-fontsize {

  .fontsize {
    padding: 0 .5rem;
    &.disabled {
      color: $black;

      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }
  }

  input {
    width: 100px;
    margin-right: 3px;
  }

  .xsmall {
    font-size: 12px;
  }

  .small {
    font-size: 14px;
  }

  .normal {
    font-size: 16px;
  }
}
.list-group {
  margin-top: .5rem;
}
