
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        











































































































.pagination {
  a.disabled {
    color: $dark-grey;

    &:hover {
      color: $dark-grey;
      background-color: transparent;
    }
  }
}
