
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        




























































































.row.chart {
  &:last-of-type {
    &:after {
      @include media-breakpoint-up(md) {
        content: "";
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }
    }
  }
}
  .chart-wrapper {
    a {
      font-size: .8em;
      color: $dark-grey;

      &:hover{
        color: $slate-grey;
      }
    }
  }

