
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        
























































































































.vue-codemirror.form-control {
  height: 100%;

  padding: 0.375rem;

  &:not(.is-invalid) {
    border-color: #e4e7ea;
  }

  &:valid {
    border-color: #e4e7ea;
  }

  /deep/ .CodeMirror {
    // height: 100%;
    height: auto;
  }

  &.readOnly {
    /deep/ .CodeMirror {
      color: $dark-grey !important;

      &.cm-s-default .cm-string {
        color: $dark-grey !important;
      }
    }
  }
}
