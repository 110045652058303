
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        
















































































































































































































































































































































































































































































































































































































































































.tab-pane-content-if {
  .row {
    height: 100%;
  }
}

.log-entry {
  font-size: .9em;
  line-height: normal;
  margin-bottom: 0;
}

.h-button {
  height: calc(1.5em + .375rem * 2);
  margin-top: 1em;
}
