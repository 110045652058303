
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        
























































































































































































































































































































































































.panelgrading-form {
  .add-action {
    font-size: 2.2em;
    margin-right: .5em;
    cursor: pointer;
  }

  .vuetable-body {
    tr {
      td {
        position: relative;

        .text-error {
          color: $red;
        }

        &.row-actions {

          @include media-breakpoint-up(md) {
            width: 8em;
          }

          .row-action {
            font-size: 2.2em;
            margin-right: .5em;
            cursor: pointer;

            &:last-of-type {
              margin-right: 0;
            }

            &.on-display {
              display: inline-block;
            }
            &.on-edit {
              display: none;
            }

            &.action-finish-edit {
              color: $green;
              span {
                border-bottom: 4px solid;
              }
            }

            &.action-delete {
              color: $red;
            }
          }
        }
      }

      .on-edit {
        display: none;
      }

      .on-display {
        display: block;
      }

      &.editing {
        background-color: $light-grey;

        .on-edit {
          display: block;
        }
        .on-display {
          display: none;
        }

        td {
          &.row-actions {
            .row-action {

              &.on-edit {
                display: inline-block;
              }
              &.on-display {
                display: none;
              }

            }
          }
        }
      }
    }
  }

  .pagination {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;

    .page-link {
      border: 0;

      &:hover {
        background-color: $botticelli;
      }
    }

    .page-item {
      border: 0;
      display: flex;
      align-items: center;
      padding: 0 0.75rem;

      flex-grow:1;
      flex-basis: 0;
      cursor: pointer;

      &:hover {
        background-color: $botticelli;
      }
    }
  }
}
