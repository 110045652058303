
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        




















































































































































































































































































/deep/ .details-enabled {
  cursor: context-menu;
}

/deep/ .vuetable-detail-row {
  background-color: rgba(0, 0, 0, 0.075);
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
}

/deep/ tr.selected {
  background-color: rgba(0, 0, 0, 0.075);
}

/deep/ tr {
  td {
    &.type-icons {
      @include media-breakpoint-up(md) {
        width: 2em;
        font-size: 1.3rem;
        text-align: center;
      }

      .material-design-icon {
        /*width: 1rem;*/
        /*height: 1rem;*/
      }
    }
  }
}

