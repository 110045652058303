
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        







































































































































































































/deep/ .input-description {
  textarea {
    border-color: $danger;
    height: 7rem;
  }
}

/deep/ .input-hint {
  textarea {
    border-color: $success;
    height: 7rem;
  }
}
