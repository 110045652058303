
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        


































































































.dashboard {
  min-height: calc(100vh - 55px);

  .row {
    padding: 3vw 0;
  }

  .logo {
    max-width: 250px;
  }
  .login-message {
    font-size: 2em;
    text-transform: capitalize;
  }
  .username {
    color: $cerulean;
  }

  .card-header {
    cursor: pointer;
  }

  .table {
    table-layout: fixed;
    /*td:nth-last-of-type(2),*/
    td:last-of-type {
      white-space: nowrap;
      width: 1%;
    }

    td:nth-last-child(2) {
      font-weight: bold;
    }
  }

  .border-bottom-danger {
    border-color: $cerulean;
  }
}
