
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        


























































































































































































































































































.guidelines {
  padding: 50px 150px;
  .row.align-items-center {
    padding: 10px 0;
    border-bottom: 1px dashed;
  }
  .col-sm {
    text-align: center;
  }
  .sample {
    display: inline-block;
    height: 30px;
    width: 30px;
    &.bg-black {
      background-color: $black;
    }
    &.bg-white {
      background-color: $white;
    }
    &.bg-orange {
      background-color: $orange;
    }
    &.bg-slate-grey {
      background-color: $slate-grey;
    }
    &.bg-dark-grey {
      background-color: $dark-grey;
    }
    &.bg-heater {
      background-color: $heater;
    }
    &.bg-light-grey {
      background-color: $light-grey;
    }
    &.bg-botticelli {
      background-color: $botticelli;
    }
    &.bg-cerulean {
      background-color: $cerulean;
    }
    &.bg-navy {
      background-color: $navy;
    }
  }
}
