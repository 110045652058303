
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        




















































.home {
  min-height: calc(100vh - 105px);

  .row {
    padding: 3vw 0;
  }

  .logo {
    max-width: 250px;
  }
  .login-message {
    font-size: 2em;
    text-transform: capitalize;
  }

}
