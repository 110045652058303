
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        


















































































































































































































































































































































.services-edit {
  .row {
    margin-bottom: 1em;
  }

  #details, #form {
    .label {
      text-align: right;
    }

    /*input, textarea {*/
      /*&:not(.v-select) {*/
        /*min-width: 250px;*/
      /*}*/
    /*}*/
  }

  .tab-pane {
    height: auto; //calc(400px + 4rem);
  }

  .nav-tabs {
    .tabs-wrapper {
      margin-bottom: 1em;
    }
  }

  .was-validated .form-control {
    &:valid {
      border-color: #e4e7ea;
    }

    &:not(.is-invalid) {
      &:not(:invalid) {
        border-color: #e4e7ea;
      }
    }
  }
}
