
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        


































































































































































































































































































































































































.status {
  font-weight: bold;

  &.status-started {
    color: $green;
  }
  &.status-stopped,
  &.status-disabled {
    color: $slate-grey
  }
}

/deep/ .started .details-enabled {
  cursor: context-menu;
}

/deep/ .vuetable-detail-row {
  background-color: rgba(0, 0, 0, 0.075);
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
}

/deep/ tr.selected {
  background-color: rgba(0, 0, 0, 0.075);
}

tr {
  .row-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .action-icon {
    padding: 2px;
    line-height: 1.2;
    &.start.color-green {
      &.started, &.disabled {
        color: $heater;
        cursor: default;
      }
    }

    &.stop.color-red {
      &.stopped, &.disabled {
        color: $heater;
        cursor: default;
      }
    }

    &.tools {
      font-size: 1.5em;
    }
  }

  &.disabled {

  }
}

