.vuetable {
  thead {
    th {
      vertical-align: middle;
      text-align: center;

      text-transform: capitalize;
      background-color: $botticelli;

      &.vuetable-th-gutter {
        display: none;
      }

      &.sortable {
        &:hover {
          color: $black;
          cursor: default;
        }

        .sort-command {
          margin-left: .3rem;
        }

        a, .material-icons {
          color: $black;

          &:hover {
            color: $link;
            cursor: pointer;
          }
        }
      }

      &.collapsed {
        max-width: 2rem;
        overflow: hidden;

        .collapse-command {
          margin-right: 0;
        }
      }

      .collapse-command {
        margin-right: .3rem;
      }
    }
  }
  tbody {
    td {
      vertical-align: middle;

      .mobile-caption {
        font-weight: bold;
        text-transform: capitalize;
      }

      .collapse-command {
        color: $black;

        &:hover {
          color: $link;
          cursor: pointer;
        }
      }
    }

    tr {
      @include media-breakpoint-down(md) {
        border: 3px solid $white;
      }

      td {
        padding: .1rem .3rem;

        &.singleline {
          white-space: nowrap;
          width: 1%;

          @include media-breakpoint-down(md) {
            width: auto;
          }
        }
      }
    }

    tr.disabled {
      td {
        background-color: $light-grey !important;
        &:not(.status) {
          color: $heater;
        }
      }
    }

    .action-icon {
      font-size: 2.2rem;
      margin-right: .2rem;
      cursor: pointer;

      &.color-green {
        color: $green;
      }

      &.color-red {
        color: $red;
      }

      &.disabled {
        color: $heater;
        cursor: default;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .vuetable-td-component-checkbox {
    text-align: center;

    input[type=checkbox] {
      vertical-align: middle;
    }
  }
}

.pagination {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;

  .page-link {
    border: 0;

    &:hover {
      background-color: $botticelli;
    }
  }

  .page-item {
    border: 0;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;

    flex-grow:1;
    flex-basis: 0;
    cursor: pointer;

    &:hover {
      background-color: $botticelli;
    }
  }
}

.bulk-actions {
}