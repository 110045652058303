.tooltip {
  display: block !important;
  z-index: 10000;
  font-family: 'DIN-Regular';
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  .tooltip-inner {
    background: $light-grey;
    color: $black;
    padding: 24px;
    max-width: 90vw;
    box-shadow: 0 5px 30px rgba(black, .1);

    @include media-breakpoint-up(md) {
      max-width: 40vw;
    }
  }

  .tooltip-arrow {
    width: 10px;
    height: 10px;
    //border-style: solid;
    position: absolute;
    //margin: 5px;
    //border-color: $light-grey;
    z-index: 10001;
    transform: rotate(-45deg);
    background-color: $light-grey;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);

      /*border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;*/
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      top: -5px;
      border-left-color: transparent !important;
      border-bottom-color: transparent !important;
      left: calc(50% - 5px);

      /*border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;*/
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      //border-width: 5px 5px 5px 0;
      //border-left-color: transparent !important;
      //border-top-color: transparent !important;
      //border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      //margin-left: 0;
      //margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      //border-width: 5px 0 5px 5px;
      //border-top-color: transparent !important;
      //border-right-color: transparent !important;
      //border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: $light-grey;
    min-width: 40vw;

    .popover-inner {
      background: $light-grey;
      color: $black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      //border-color: $color;
      background-color: $light-grey;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}