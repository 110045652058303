
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        























































































































.monitored-machines {
  .status {
    $box-shadow-px: 5px;

    position: relative;
    width: 20%;
    // color: $yellow;
    border-color: $yellow;
    border-width: 2px;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 $box-shadow-px $yellow;

    &.status-started {
      .icon-wrapper {
        color: $green;
      }
      border-color: $green;
      box-shadow: 0 0 $box-shadow-px $green;
    }

    &.status-stopped {
      .icon-wrapper {
        color: $red;
      }
      border-color: $red;
      box-shadow: 0 0 $box-shadow-px $red;
    }

    &.status-disabled {
      .icon-wrapper {
        color: $slate-grey;
      }
      border-color: $slate-grey;
      box-shadow: none;
    }

    .icon-wrapper {
      color: $yellow;
      /*border: 1px solid;*/
      width: 1.7rem;
      height: 1.7rem;
      overflow: hidden;
      border-radius: 50%;
      position: absolute;
      background-color: $white;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);

      display: flex;
      /*align-items: center;*/
      justify-content: center;

      .material-design-icon {
        font-size: 1.3rem;
        svg {
          margin-bottom: 2px;
        }
      }
    }
  }
}
