
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        
























.routing-table-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 10px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .column-left {
    flex: 1 1 calc(50% - 84px);
    position: relative;
  }

  .column-center {
    flex: 0 0 168px;
    position: relative;
    text-align: center;
    display: none;

    @include media-breakpoint-up(md) {
      display: initial;
    }
  }

  .column-right {
    flex: 1 1 calc(50% - 84px);
    position: relative;
  }

  &.inverted {
    margin-bottom: 10px;

    .column-left {
      flex: 1 1 calc(84px);
    }
    .column-center {
      flex: 0 1 calc(50% - 84px);
    }
    .column-right {
      flex: 1 1 calc(84px);
    }
  }
}
