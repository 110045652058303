
          @import "@coreui/coreui/scss/bootstrap/_functions.scss";
          @import "@coreui/coreui/scss/bootstrap/_variables.scss";
          @import "@coreui/coreui/scss/bootstrap/mixins/_breakpoints.scss";
          @import "@/styles/_colors.scss";
          @import "@/styles/_variables.scss";
        















































































































































































































































































































































































































































































































.routing-form {
  height: 100%;
}

.fullscreen {

  /deep/
  .modal-wrapper {

  }

  /deep/ .modal-container {
    width: 95vw;
    height: 95vh;

    .modal-body {
      width: 100%;
      height: 100%;
      overflow: auto;

      box-sizing: border-box;
      padding: 0;
      // margin: 1em 0;

      .container {
        max-width: none;
        padding: 0;

        .routing-menu {
          margin: 10px 10px 20px 10px;
          overflow: hidden;
          border-radius: 5px;
          position: relative;
          @include media-breakpoint-up(md) {

          }

          .toggle {
            position: absolute;

            display: flex;
            justify-content: center;
            align-items: center;

            border: 1px solid $light-grey;
            border-radius: 5px;
            width: 2.5em;
            height: 2.5em;

            z-index: $zindexInteraction;
            background-color: $white;
            cursor: pointer;

            .action-icon {
              color: $dark-grey;
            }

            &.active {
              background-color: $light-grey;
              border-color: $dark-grey;
            }
          }

          .routing-menu-items {
            height: 2.5em;

            display: inline-flex;
            padding: 0 0 0 2.5em;
            justify-content: space-between;
            align-items: stretch;

            max-width: 100%;
            overflow-x: auto;
            overflow-y: hidden;

            transform: translateX(-100%);
            opacity: 0;
            transition: opacity 0.15s  ease-out, transform 0.2s ease-out;

            &.opened {
              transform: none;
              z-index: 1;
              opacity: 1;
            }

            > a {
              border: 1px solid $light-grey;
              border-radius: 5px;
              white-space: nowrap;

              flex: 1 1 auto;
              padding: 0 10px;
              margin: 0 5px;

              display: flex;
              align-items: center;
              color: $white;

              &:hover {
                text-decoration: none;
              }

              &.add-top-action {
                background-color: $gold;
              }
              &.add-left-action {
                background-color: $cerulean;
              }
              &.add-right-action {
                background-color: $red;
              }
              &.change-order-action {
                background-color: $botticelli;
                color: $black;
              }

              &.disabled {
                background-color: $light-grey;
              }
            }
          }
        }

        .routing-table {
          .top-exits {
            .exit {
              margin-bottom: 10px;

              @include media-breakpoint-up(md) {
                margin-bottom: 0;
              }
            }
          }

          .exit-labels {
            display: none;

            @include media-breakpoint-up(md) {
              display: flex;
            }
          }

          .top-arrow__icon {
            width: 50px;
            height: auto;
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);

            &.single {
              position: relative;
              display: block;
              bottom: 0;
              margin: 10px 0;
            }
          }

          .row {
            margin: 0;
            font-size: 0;
          }

          .cushion {
            flex: 1 1 auto;
          }

          .exit-wrapper {
            padding-bottom: 10px;
            font-size: initial;
            width: calc(50% - 83px);
            flex: 0 0 auto;

            &:first-of-type {
              padding-left: 10px;
            }
            &:last-of-type {
              padding-right: 10px;
            }

            .right-arrow, .left-arrow {
              margin-top: 20px;
              height: 40px;
              width: auto;
            }
          }

          .top {
            .exit-wrapper {
              /*margin: 0 auto;*/
              display: block;
            }
          }

          .bend-wrapper {
            width: 168px;
            flex: 0 0 168px;
          }

          /*.routes-wrapper {
            width: 168px;
            flex: 0 0 168px;

            display: none;

            @include media-breakpoint-up(md) {
              display: initial;
            }
          }*/

          .entry-wrapper {
            min-width: 168px;
            font-size: initial;
            position: relative;

            .lines {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              z-index: 0;
            }

            .entry {
              position: relative;
              width: 8em;
              z-index: 1;
            }

            .entry-text {
              position: absolute;
              bottom: 0.5em;
              left: 50%;
              transform: translateX(-50%);
              text-transform: uppercase;
              color: $white;
              z-index: 2;
            }
          }

          .add-exit {
            border-radius: 3px;
            cursor: pointer;
            opacity: .1;
            &:hover {
              opacity: 1;
            }

            /deep/ .action-icon {
              color: $light-grey;

              font-size: initial;
            }
          }

          .column-left, .column-right {
            .column {
              display: flex;
              flex-direction: column;
              height: 100%;

              .exit {
                margin-bottom: 10px;
              }
            }
          }

          .other-fields-wrapper {
            text-align: center;
            display: flex;
            margin-top: 3em;
            margin-bottom: 3em;
            justify-content: center;
            flex-direction: column;

            @include media-breakpoint-up(md) {
              flex-direction: row;
            }

            .notes-editor {
              text-align: left;
              margin: 0 5px;
              textarea {
                width: 100%;
                @include media-breakpoint-up(md) {
                  width: 33vw;
                }
                height: 4em;
              }

              label {
                display: block;
                margin-right: 1em;
              }
            }

            .routing-system-editor {
              text-align: left;
              margin: 0 5px;
              .input-select {
                width: 100%;
                @include media-breakpoint-up(md) {
                  width: 33vw;
                }
              }
              label {
                display: block;
                margin-right: 1em;
              }
            }
          }
        }
      }
    }
  }
}
