/* pt-sans-regular - latin */
@font-face {
  font-family: 'DIN-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('PT Sans'), local('PTSans-Regular'),
  url('~@/assets/fonts/pt-sans-v10-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/fonts/pt-sans-v10-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* pt-sans-700 - latin */
@font-face {
  font-family: 'DIN-Regular';
  font-style: normal;
  font-weight: 700;
  src: local('PT Sans Bold'), local('PTSans-Bold'),
  url('~@/assets/fonts/pt-sans-v10-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('~@/assets/fonts/pt-sans-v10-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('~@/assets/fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('~@/assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
  url('~@/assets/fonts/MaterialIcons-Regular.woff') format('woff'),
  url('~@/assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons, .mi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


/* Rules for sizing the icon. */
.material-icons.md-18, .mi.md-18 { font-size: 18px; }
.material-icons.md-24, .mi.md-24 { font-size: 24px; }
.material-icons.md-36, .mi.md-36 { font-size: 36px; }
.material-icons.md-48, .mi.md-48 { font-size: 48px; }

.material-icons.md-08, .mi.em-08 { font-size: .8em; }
.material-icons.md-08, .mi.em-09 { font-size: .9em; }
.material-icons.em-1, .mi.em-1 { font-size: 1em; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark, .mi.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive, .mi.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light, .mi.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive, .mi.md-inactive { color: rgba(255, 255, 255, 0.3); }

/* Rules to rotate items */
.material-icons.r90, .mi.r90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.material-icons.r180, .mi.r180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.material-icons.r270, .mi.r270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.material-icons.flip-horizontal, .mi.flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.material-icons.flip-vertical, .mi.flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

/* Unicode */
.material-icons.three_d_rotation:after,
.mi.three_d_rotation:after {
  content: '\e84d';
}
.material-icons.ac_unit:after,
.mi.ac_unit:after {
  content: '\eb3b';
}
.material-icons.access_alarm:after,
.mi.access_alarm:after {
  content: '\e190';
}
.material-icons.access_alarms:after,
.mi.access_alarms:after {
  content: '\e191';
}
.material-icons.access_time:after,
.mi.access_time:after {
  content: '\e192';
}
.material-icons.accessibility:after,
.mi.accessibility:after {
  content: '\e84e';
}
.material-icons.accessible:after,
.mi.accessible:after {
  content: '\e914';
}
.material-icons.account_balance:after,
.mi.account_balance:after {
  content: '\e84f';
}
.material-icons.account_balance_wallet:after,
.mi.account_balance_wallet:after {
  content: '\e850';
}
.material-icons.account_box:after,
.mi.account_box:after {
  content: '\e851';
}
.material-icons.account_circle:after,
.mi.account_circle:after {
  content: '\e853';
}
.material-icons.adb:after,
.mi.adb:after {
  content: '\e60e';
}
.material-icons.add:after,
.mi.add:after {
  content: '\e145';
}
.material-icons.add_a_photo:after,
.mi.add_a_photo:after {
  content: '\e439';
}
.material-icons.add_alarm:after,
.mi.add_alarm:after {
  content: '\e193';
}
.material-icons.add_alert:after,
.mi.add_alert:after {
  content: '\e003';
}
.material-icons.add_box:after,
.mi.add_box:after {
  content: '\e146';
}
.material-icons.add_circle:after,
.mi.add_circle:after {
  content: '\e147';
}
.material-icons.add_circle_outline:after,
.mi.add_circle_outline:after {
  content: '\e148';
}
.material-icons.add_location:after,
.mi.add_location:after {
  content: '\e567';
}
.material-icons.add_shopping_cart:after,
.mi.add_shopping_cart:after {
  content: '\e854';
}
.material-icons.add_to_photos:after,
.mi.add_to_photos:after {
  content: '\e39d';
}
.material-icons.add_to_queue:after,
.mi.add_to_queue:after {
  content: '\e05c';
}
.material-icons.adjust:after,
.mi.adjust:after {
  content: '\e39e';
}
.material-icons.airline_seat_flat:after,
.mi.airline_seat_flat:after {
  content: '\e630';
}
.material-icons.airline_seat_flat_angled:after,
.mi.airline_seat_flat_angled:after {
  content: '\e631';
}
.material-icons.airline_seat_individual_suite:after,
.mi.airline_seat_individual_suite:after {
  content: '\e632';
}
.material-icons.airline_seat_legroom_extra:after,
.mi.airline_seat_legroom_extra:after {
  content: '\e633';
}
.material-icons.airline_seat_legroom_normal:after,
.mi.airline_seat_legroom_normal:after {
  content: '\e634';
}
.material-icons.airline_seat_legroom_reduced:after,
.mi.airline_seat_legroom_reduced:after {
  content: '\e635';
}
.material-icons.airline_seat_recline_extra:after,
.mi.airline_seat_recline_extra:after {
  content: '\e636';
}
.material-icons.airline_seat_recline_normal:after,
.mi.airline_seat_recline_normal:after {
  content: '\e637';
}
.material-icons.airplanemode_active:after,
.mi.airplanemode_active:after {
  content: '\e195';
}
.material-icons.airplanemode_inactive:after,
.mi.airplanemode_inactive:after {
  content: '\e194';
}
.material-icons.airplay:after,
.mi.airplay:after {
  content: '\e055';
}
.material-icons.airport_shuttle:after,
.mi.airport_shuttle:after {
  content: '\eb3c';
}
.material-icons.alarm:after,
.mi.alarm:after {
  content: '\e855';
}
.material-icons.alarm_add:after,
.mi.alarm_add:after {
  content: '\e856';
}
.material-icons.alarm_off:after,
.mi.alarm_off:after {
  content: '\e857';
}
.material-icons.alarm_on:after,
.mi.alarm_on:after {
  content: '\e858';
}
.material-icons.album:after,
.mi.album:after {
  content: '\e019';
}
.material-icons.all_inclusive:after,
.mi.all_inclusive:after {
  content: '\eb3d';
}
.material-icons.all_out:after,
.mi.all_out:after {
  content: '\e90b';
}
.material-icons.android:after,
.mi.android:after {
  content: '\e859';
}
.material-icons.announcement:after,
.mi.announcement:after {
  content: '\e85a';
}
.material-icons.apps:after,
.mi.apps:after {
  content: '\e5c3';
}
.material-icons.archive:after,
.mi.archive:after {
  content: '\e149';
}
.material-icons.arrow_back:after,
.mi.arrow_back:after {
  content: '\e5c4';
}
.material-icons.arrow_downward:after,
.mi.arrow_downward:after {
  content: '\e5db';
}
.material-icons.arrow_drop_down:after,
.mi.arrow_drop_down:after {
  content: '\e5c5';
}
.material-icons.arrow_drop_down_circle:after,
.mi.arrow_drop_down_circle:after {
  content: '\e5c6';
}
.material-icons.arrow_drop_up:after,
.mi.arrow_drop_up:after {
  content: '\e5c7';
}
.material-icons.arrow_forward:after,
.mi.arrow_forward:after {
  content: '\e5c8';
}
.material-icons.arrow_upward:after,
.mi.arrow_upward:after {
  content: '\e5d8';
}
.material-icons.art_track:after,
.mi.art_track:after {
  content: '\e060';
}
.material-icons.aspect_ratio:after,
.mi.aspect_ratio:after {
  content: '\e85b';
}
.material-icons.assessment:after,
.mi.assessment:after {
  content: '\e85c';
}
.material-icons.assignment:after,
.mi.assignment:after {
  content: '\e85d';
}
.material-icons.assignment_ind:after,
.mi.assignment_ind:after {
  content: '\e85e';
}
.material-icons.assignment_late:after,
.mi.assignment_late:after {
  content: '\e85f';
}
.material-icons.assignment_return:after,
.mi.assignment_return:after {
  content: '\e860';
}
.material-icons.assignment_returned:after,
.mi.assignment_returned:after {
  content: '\e861';
}
.material-icons.assignment_turned_in:after,
.mi.assignment_turned_in:after {
  content: '\e862';
}
.material-icons.assistant:after,
.mi.assistant:after {
  content: '\e39f';
}
.material-icons.assistant_photo:after,
.mi.assistant_photo:after {
  content: '\e3a0';
}
.material-icons.attach_file:after,
.mi.attach_file:after {
  content: '\e226';
}
.material-icons.attach_money:after,
.mi.attach_money:after {
  content: '\e227';
}
.material-icons.attachment:after,
.mi.attachment:after {
  content: '\e2bc';
}
.material-icons.audiotrack:after,
.mi.audiotrack:after {
  content: '\e3a1';
}
.material-icons.autorenew:after,
.mi.autorenew:after {
  content: '\e863';
}
.material-icons.av_timer:after,
.mi.av_timer:after {
  content: '\e01b';
}
.material-icons.backspace:after,
.mi.backspace:after {
  content: '\e14a';
}
.material-icons.backup:after,
.mi.backup:after {
  content: '\e864';
}
.material-icons.battery_alert:after,
.mi.battery_alert:after {
  content: '\e19c';
}
.material-icons.battery_charging_full:after,
.mi.battery_charging_full:after {
  content: '\e1a3';
}
.material-icons.battery_full:after,
.mi.battery_full:after {
  content: '\e1a4';
}
.material-icons.battery_std:after,
.mi.battery_std:after {
  content: '\e1a5';
}
.material-icons.battery_unknown:after,
.mi.battery_unknown:after {
  content: '\e1a6';
}
.material-icons.beach_access:after,
.mi.beach_access:after {
  content: '\eb3e';
}
.material-icons.beenhere:after,
.mi.beenhere:after {
  content: '\e52d';
}
.material-icons.block:after,
.mi.block:after {
  content: '\e14b';
}
.material-icons.bluetooth:after,
.mi.bluetooth:after {
  content: '\e1a7';
}
.material-icons.bluetooth_audio:after,
.mi.bluetooth_audio:after {
  content: '\e60f';
}
.material-icons.bluetooth_connected:after,
.mi.bluetooth_connected:after {
  content: '\e1a8';
}
.material-icons.bluetooth_disabled:after,
.mi.bluetooth_disabled:after {
  content: '\e1a9';
}
.material-icons.bluetooth_searching:after,
.mi.bluetooth_searching:after {
  content: '\e1aa';
}
.material-icons.blur_circular:after,
.mi.blur_circular:after {
  content: '\e3a2';
}
.material-icons.blur_linear:after,
.mi.blur_linear:after {
  content: '\e3a3';
}
.material-icons.blur_off:after,
.mi.blur_off:after {
  content: '\e3a4';
}
.material-icons.blur_on:after,
.mi.blur_on:after {
  content: '\e3a5';
}
.material-icons.book:after,
.mi.book:after {
  content: '\e865';
}
.material-icons.bookmark:after,
.mi.bookmark:after {
  content: '\e866';
}
.material-icons.bookmark_border:after,
.mi.bookmark_border:after {
  content: '\e867';
}
.material-icons.border_all:after,
.mi.border_all:after {
  content: '\e228';
}
.material-icons.border_bottom:after,
.mi.border_bottom:after {
  content: '\e229';
}
.material-icons.border_clear:after,
.mi.border_clear:after {
  content: '\e22a';
}
.material-icons.border_color:after,
.mi.border_color:after {
  content: '\e22b';
}
.material-icons.border_horizontal:after,
.mi.border_horizontal:after {
  content: '\e22c';
}
.material-icons.border_inner:after,
.mi.border_inner:after {
  content: '\e22d';
}
.material-icons.border_left:after,
.mi.border_left:after {
  content: '\e22e';
}
.material-icons.border_outer:after,
.mi.border_outer:after {
  content: '\e22f';
}
.material-icons.border_right:after,
.mi.border_right:after {
  content: '\e230';
}
.material-icons.border_style:after,
.mi.border_style:after {
  content: '\e231';
}
.material-icons.border_top:after,
.mi.border_top:after {
  content: '\e232';
}
.material-icons.border_vertical:after,
.mi.border_vertical:after {
  content: '\e233';
}
.material-icons.branding_watermark:after,
.mi.branding_watermark:after {
  content: '\e06b';
}
.material-icons.brightness_1:after,
.mi.brightness_1:after {
  content: '\e3a6';
}
.material-icons.brightness_2:after,
.mi.brightness_2:after {
  content: '\e3a7';
}
.material-icons.brightness_3:after,
.mi.brightness_3:after {
  content: '\e3a8';
}
.material-icons.brightness_4:after,
.mi.brightness_4:after {
  content: '\e3a9';
}
.material-icons.brightness_5:after,
.mi.brightness_5:after {
  content: '\e3aa';
}
.material-icons.brightness_6:after,
.mi.brightness_6:after {
  content: '\e3ab';
}
.material-icons.brightness_7:after,
.mi.brightness_7:after {
  content: '\e3ac';
}
.material-icons.brightness_auto:after,
.mi.brightness_auto:after {
  content: '\e1ab';
}
.material-icons.brightness_high:after,
.mi.brightness_high:after {
  content: '\e1ac';
}
.material-icons.brightness_low:after,
.mi.brightness_low:after {
  content: '\e1ad';
}
.material-icons.brightness_medium:after,
.mi.brightness_medium:after {
  content: '\e1ae';
}
.material-icons.broken_image:after,
.mi.broken_image:after {
  content: '\e3ad';
}
.material-icons.brush:after,
.mi.brush:after {
  content: '\e3ae';
}
.material-icons.bubble_chart:after,
.mi.bubble_chart:after {
  content: '\e6dd';
}
.material-icons.bug_report:after,
.mi.bug_report:after {
  content: '\e868';
}
.material-icons.build:after,
.mi.build:after {
  content: '\e869';
}
.material-icons.burst_mode:after,
.mi.burst_mode:after {
  content: '\e43c';
}
.material-icons.business:after,
.mi.business:after {
  content: '\e0af';
}
.material-icons.business_center:after,
.mi.business_center:after {
  content: '\eb3f';
}
.material-icons.cached:after,
.mi.cached:after {
  content: '\e86a';
}
.material-icons.cake:after,
.mi.cake:after {
  content: '\e7e9';
}
.material-icons.call:after,
.mi.call:after {
  content: '\e0b0';
}
.material-icons.call_end:after,
.mi.call_end:after {
  content: '\e0b1';
}
.material-icons.call_made:after,
.mi.call_made:after {
  content: '\e0b2';
}
.material-icons.call_merge:after,
.mi.call_merge:after {
  content: '\e0b3';
}
.material-icons.call_missed:after,
.mi.call_missed:after {
  content: '\e0b4';
}
.material-icons.call_missed_outgoing:after,
.mi.call_missed_outgoing:after {
  content: '\e0e4';
}
.material-icons.call_received:after,
.mi.call_received:after {
  content: '\e0b5';
}
.material-icons.call_split:after,
.mi.call_split:after {
  content: '\e0b6';
}
.material-icons.call_to_action:after,
.mi.call_to_action:after {
  content: '\e06c';
}
.material-icons.camera:after,
.mi.camera:after {
  content: '\e3af';
}
.material-icons.camera_alt:after,
.mi.camera_alt:after {
  content: '\e3b0';
}
.material-icons.camera_enhance:after,
.mi.camera_enhance:after {
  content: '\e8fc';
}
.material-icons.camera_front:after,
.mi.camera_front:after {
  content: '\e3b1';
}
.material-icons.camera_rear:after,
.mi.camera_rear:after {
  content: '\e3b2';
}
.material-icons.camera_roll:after,
.mi.camera_roll:after {
  content: '\e3b3';
}
.material-icons.cancel:after,
.mi.cancel:after {
  content: '\e5c9';
}
.material-icons.card_giftcard:after,
.mi.card_giftcard:after {
  content: '\e8f6';
}
.material-icons.card_membership:after,
.mi.card_membership:after {
  content: '\e8f7';
}
.material-icons.card_travel:after,
.mi.card_travel:after {
  content: '\e8f8';
}
.material-icons.casino:after,
.mi.casino:after {
  content: '\eb40';
}
.material-icons.cast:after,
.mi.cast:after {
  content: '\e307';
}
.material-icons.cast_connected:after,
.mi.cast_connected:after {
  content: '\e308';
}
.material-icons.center_focus_strong:after,
.mi.center_focus_strong:after {
  content: '\e3b4';
}
.material-icons.center_focus_weak:after,
.mi.center_focus_weak:after {
  content: '\e3b5';
}
.material-icons.change_history:after,
.mi.change_history:after {
  content: '\e86b';
}
.material-icons.chat:after,
.mi.chat:after {
  content: '\e0b7';
}
.material-icons.chat_bubble:after,
.mi.chat_bubble:after {
  content: '\e0ca';
}
.material-icons.chat_bubble_outline:after,
.mi.chat_bubble_outline:after {
  content: '\e0cb';
}
.material-icons.check:after,
.mi.check:after {
  content: '\e5ca';
}
.material-icons.check_box:after,
.mi.check_box:after {
  content: '\e834';
}
.material-icons.check_box_outline_blank:after,
.mi.check_box_outline_blank:after {
  content: '\e835';
}
.material-icons.check_circle:after,
.mi.check_circle:after {
  content: '\e86c';
}
.material-icons.chevron_left:after,
.mi.chevron_left:after {
  content: '\e5cb';
}
.material-icons.chevron_right:after,
.mi.chevron_right:after {
  content: '\e5cc';
}
.material-icons.child_care:after,
.mi.child_care:after {
  content: '\eb41';
}
.material-icons.child_friendly:after,
.mi.child_friendly:after {
  content: '\eb42';
}
.material-icons.chrome_reader_mode:after,
.mi.chrome_reader_mode:after {
  content: '\e86d';
}
.material-icons.class:after,
.mi.class:after {
  content: '\e86e';
}
.material-icons.clear:after,
.mi.clear:after {
  content: '\e14c';
}
.material-icons.clear_all:after,
.mi.clear_all:after {
  content: '\e0b8';
}
.material-icons.close:after,
.mi.close:after {
  content: '\e5cd';
}
.material-icons.closed_caption:after,
.mi.closed_caption:after {
  content: '\e01c';
}
.material-icons.cloud:after,
.mi.cloud:after {
  content: '\e2bd';
}
.material-icons.cloud_circle:after,
.mi.cloud_circle:after {
  content: '\e2be';
}
.material-icons.cloud_done:after,
.mi.cloud_done:after {
  content: '\e2bf';
}
.material-icons.cloud_download:after,
.mi.cloud_download:after {
  content: '\e2c0';
}
.material-icons.cloud_off:after,
.mi.cloud_off:after {
  content: '\e2c1';
}
.material-icons.cloud_queue:after,
.mi.cloud_queue:after {
  content: '\e2c2';
}
.material-icons.cloud_upload:after,
.mi.cloud_upload:after {
  content: '\e2c3';
}
.material-icons.code:after,
.mi.code:after {
  content: '\e86f';
}
.material-icons.collections:after,
.mi.collections:after {
  content: '\e3b6';
}
.material-icons.collections_bookmark:after,
.mi.collections_bookmark:after {
  content: '\e431';
}
.material-icons.color_lens:after,
.mi.color_lens:after {
  content: '\e3b7';
}
.material-icons.colorize:after,
.mi.colorize:after {
  content: '\e3b8';
}
.material-icons.comment:after,
.mi.comment:after {
  content: '\e0b9';
}
.material-icons.compare:after,
.mi.compare:after {
  content: '\e3b9';
}
.material-icons.compare_arrows:after,
.mi.compare_arrows:after {
  content: '\e915';
}
.material-icons.computer:after,
.mi.computer:after {
  content: '\e30a';
}
.material-icons.confirmation_number:after,
.mi.confirmation_number:after {
  content: '\e638';
}
.material-icons.contact_mail:after,
.mi.contact_mail:after {
  content: '\e0d0';
}
.material-icons.contact_phone:after,
.mi.contact_phone:after {
  content: '\e0cf';
}
.material-icons.contacts:after,
.mi.contacts:after {
  content: '\e0ba';
}
.material-icons.content_copy:after,
.mi.content_copy:after {
  content: '\e14d';
}
.material-icons.content_cut:after,
.mi.content_cut:after {
  content: '\e14e';
}
.material-icons.content_paste:after,
.mi.content_paste:after {
  content: '\e14f';
}
.material-icons.control_point:after,
.mi.control_point:after {
  content: '\e3ba';
}
.material-icons.control_point_duplicate:after,
.mi.control_point_duplicate:after {
  content: '\e3bb';
}
.material-icons.copyright:after,
.mi.copyright:after {
  content: '\e90c';
}
.material-icons.create:after,
.mi.create:after {
  content: '\e150';
}
.material-icons.create_new_folder:after,
.mi.create_new_folder:after {
  content: '\e2cc';
}
.material-icons.credit_card:after,
.mi.credit_card:after {
  content: '\e870';
}
.material-icons.crop:after,
.mi.crop:after {
  content: '\e3be';
}
.material-icons.crop_16_9:after,
.mi.crop_16_9:after {
  content: '\e3bc';
}
.material-icons.crop_3_2:after,
.mi.crop_3_2:after {
  content: '\e3bd';
}
.material-icons.crop_5_4:after,
.mi.crop_5_4:after {
  content: '\e3bf';
}
.material-icons.crop_7_5:after,
.mi.crop_7_5:after {
  content: '\e3c0';
}
.material-icons.crop_din:after,
.mi.crop_din:after {
  content: '\e3c1';
}
.material-icons.crop_free:after,
.mi.crop_free:after {
  content: '\e3c2';
}
.material-icons.crop_landscape:after,
.mi.crop_landscape:after {
  content: '\e3c3';
}
.material-icons.crop_original:after,
.mi.crop_original:after {
  content: '\e3c4';
}
.material-icons.crop_portrait:after,
.mi.crop_portrait:after {
  content: '\e3c5';
}
.material-icons.crop_rotate:after,
.mi.crop_rotate:after {
  content: '\e437';
}
.material-icons.crop_square:after,
.mi.crop_square:after {
  content: '\e3c6';
}
.material-icons.dashboard:after,
.mi.dashboard:after {
  content: '\e871';
}
.material-icons.data_usage:after,
.mi.data_usage:after {
  content: '\e1af';
}
.material-icons.date_range:after,
.mi.date_range:after {
  content: '\e916';
}
.material-icons.dehaze:after,
.mi.dehaze:after {
  content: '\e3c7';
}
.material-icons.delete:after,
.mi.delete:after {
  content: '\e872';
}
.material-icons.delete_forever:after,
.mi.delete_forever:after {
  content: '\e92b';
}
.material-icons.delete_sweep:after,
.mi.delete_sweep:after {
  content: '\e16c';
}
.material-icons.description:after,
.mi.description:after {
  content: '\e873';
}
.material-icons.desktop_mac:after,
.mi.desktop_mac:after {
  content: '\e30b';
}
.material-icons.desktop_windows:after,
.mi.desktop_windows:after {
  content: '\e30c';
}
.material-icons.details:after,
.mi.details:after {
  content: '\e3c8';
}
.material-icons.developer_board:after,
.mi.developer_board:after {
  content: '\e30d';
}
.material-icons.developer_mode:after,
.mi.developer_mode:after {
  content: '\e1b0';
}
.material-icons.device_hub:after,
.mi.device_hub:after {
  content: '\e335';
}
.material-icons.devices:after,
.mi.devices:after {
  content: '\e1b1';
}
.material-icons.devices_other:after,
.mi.devices_other:after {
  content: '\e337';
}
.material-icons.dialer_sip:after,
.mi.dialer_sip:after {
  content: '\e0bb';
}
.material-icons.dialpad:after,
.mi.dialpad:after {
  content: '\e0bc';
}
.material-icons.directions:after,
.mi.directions:after {
  content: '\e52e';
}
.material-icons.directions_bike:after,
.mi.directions_bike:after {
  content: '\e52f';
}
.material-icons.directions_boat:after,
.mi.directions_boat:after {
  content: '\e532';
}
.material-icons.directions_bus:after,
.mi.directions_bus:after {
  content: '\e530';
}
.material-icons.directions_car:after,
.mi.directions_car:after {
  content: '\e531';
}
.material-icons.directions_railway:after,
.mi.directions_railway:after {
  content: '\e534';
}
.material-icons.directions_run:after,
.mi.directions_run:after {
  content: '\e566';
}
.material-icons.directions_subway:after,
.mi.directions_subway:after {
  content: '\e533';
}
.material-icons.directions_transit:after,
.mi.directions_transit:after {
  content: '\e535';
}
.material-icons.directions_walk:after,
.mi.directions_walk:after {
  content: '\e536';
}
.material-icons.disc_full:after,
.mi.disc_full:after {
  content: '\e610';
}
.material-icons.dns:after,
.mi.dns:after {
  content: '\e875';
}
.material-icons.do_not_disturb:after,
.mi.do_not_disturb:after {
  content: '\e612';
}
.material-icons.do_not_disturb_alt:after,
.mi.do_not_disturb_alt:after {
  content: '\e611';
}
.material-icons.do_not_disturb_off:after,
.mi.do_not_disturb_off:after {
  content: '\e643';
}
.material-icons.do_not_disturb_on:after,
.mi.do_not_disturb_on:after {
  content: '\e644';
}
.material-icons.dock:after,
.mi.dock:after {
  content: '\e30e';
}
.material-icons.domain:after,
.mi.domain:after {
  content: '\e7ee';
}
.material-icons.done:after,
.mi.done:after {
  content: '\e876';
}
.material-icons.done_all:after,
.mi.done_all:after {
  content: '\e877';
}
.material-icons.donut_large:after,
.mi.donut_large:after {
  content: '\e917';
}
.material-icons.donut_small:after,
.mi.donut_small:after {
  content: '\e918';
}
.material-icons.drafts:after,
.mi.drafts:after {
  content: '\e151';
}
.material-icons.drag_handle:after,
.mi.drag_handle:after {
  content: '\e25d';
}
.material-icons.drive_eta:after,
.mi.drive_eta:after {
  content: '\e613';
}
.material-icons.dvr:after,
.mi.dvr:after {
  content: '\e1b2';
}
.material-icons.edit:after,
.mi.edit:after {
  content: '\e3c9';
}
.material-icons.edit_location:after,
.mi.edit_location:after {
  content: '\e568';
}
.material-icons.eject:after,
.mi.eject:after {
  content: '\e8fb';
}
.material-icons.email:after,
.mi.email:after {
  content: '\e0be';
}
.material-icons.enhanced_encryption:after,
.mi.enhanced_encryption:after {
  content: '\e63f';
}
.material-icons.equalizer:after,
.mi.equalizer:after {
  content: '\e01d';
}
.material-icons.error:after,
.mi.error:after {
  content: '\e000';
}
.material-icons.error_outline:after,
.mi.error_outline:after {
  content: '\e001';
}
.material-icons.euro_symbol:after,
.mi.euro_symbol:after {
  content: '\e926';
}
.material-icons.ev_station:after,
.mi.ev_station:after {
  content: '\e56d';
}
.material-icons.event:after,
.mi.event:after {
  content: '\e878';
}
.material-icons.event_available:after,
.mi.event_available:after {
  content: '\e614';
}
.material-icons.event_busy:after,
.mi.event_busy:after {
  content: '\e615';
}
.material-icons.event_note:after,
.mi.event_note:after {
  content: '\e616';
}
.material-icons.event_seat:after,
.mi.event_seat:after {
  content: '\e903';
}
.material-icons.exit_to_app:after,
.mi.exit_to_app:after {
  content: '\e879';
}
.material-icons.expand_less:after,
.mi.expand_less:after {
  content: '\e5ce';
}
.material-icons.expand_more:after,
.mi.expand_more:after {
  content: '\e5cf';
}
.material-icons.explicit:after,
.mi.explicit:after {
  content: '\e01e';
}
.material-icons.explore:after,
.mi.explore:after {
  content: '\e87a';
}
.material-icons.exposure:after,
.mi.exposure:after {
  content: '\e3ca';
}
.material-icons.exposure_neg_1:after,
.mi.exposure_neg_1:after {
  content: '\e3cb';
}
.material-icons.exposure_neg_2:after,
.mi.exposure_neg_2:after {
  content: '\e3cc';
}
.material-icons.exposure_plus_1:after,
.mi.exposure_plus_1:after {
  content: '\e3cd';
}
.material-icons.exposure_plus_2:after,
.mi.exposure_plus_2:after {
  content: '\e3ce';
}
.material-icons.exposure_zero:after,
.mi.exposure_zero:after {
  content: '\e3cf';
}
.material-icons.extension:after,
.mi.extension:after {
  content: '\e87b';
}
.material-icons.face:after,
.mi.face:after {
  content: '\e87c';
}
.material-icons.fast_forward:after,
.mi.fast_forward:after {
  content: '\e01f';
}
.material-icons.fast_rewind:after,
.mi.fast_rewind:after {
  content: '\e020';
}
.material-icons.favorite:after,
.mi.favorite:after {
  content: '\e87d';
}
.material-icons.favorite_border:after,
.mi.favorite_border:after {
  content: '\e87e';
}
.material-icons.featured_play_list:after,
.mi.featured_play_list:after {
  content: '\e06d';
}
.material-icons.featured_video:after,
.mi.featured_video:after {
  content: '\e06e';
}
.material-icons.feedback:after,
.mi.feedback:after {
  content: '\e87f';
}
.material-icons.fiber_dvr:after,
.mi.fiber_dvr:after {
  content: '\e05d';
}
.material-icons.fiber_manual_record:after,
.mi.fiber_manual_record:after {
  content: '\e061';
}
.material-icons.fiber_new:after,
.mi.fiber_new:after {
  content: '\e05e';
}
.material-icons.fiber_pin:after,
.mi.fiber_pin:after {
  content: '\e06a';
}
.material-icons.fiber_smart_record:after,
.mi.fiber_smart_record:after {
  content: '\e062';
}
.material-icons.file_download:after,
.mi.file_download:after {
  content: '\e2c4';
}
.material-icons.file_upload:after,
.mi.file_upload:after {
  content: '\e2c6';
}
.material-icons.filter:after,
.mi.filter:after {
  content: '\e3d3';
}
.material-icons.filter_1:after,
.mi.filter_1:after {
  content: '\e3d0';
}
.material-icons.filter_2:after,
.mi.filter_2:after {
  content: '\e3d1';
}
.material-icons.filter_3:after,
.mi.filter_3:after {
  content: '\e3d2';
}
.material-icons.filter_4:after,
.mi.filter_4:after {
  content: '\e3d4';
}
.material-icons.filter_5:after,
.mi.filter_5:after {
  content: '\e3d5';
}
.material-icons.filter_6:after,
.mi.filter_6:after {
  content: '\e3d6';
}
.material-icons.filter_7:after,
.mi.filter_7:after {
  content: '\e3d7';
}
.material-icons.filter_8:after,
.mi.filter_8:after {
  content: '\e3d8';
}
.material-icons.filter_9:after,
.mi.filter_9:after {
  content: '\e3d9';
}
.material-icons.filter_9_plus:after,
.mi.filter_9_plus:after {
  content: '\e3da';
}
.material-icons.filter_b_and_w:after,
.mi.filter_b_and_w:after {
  content: '\e3db';
}
.material-icons.filter_center_focus:after,
.mi.filter_center_focus:after {
  content: '\e3dc';
}
.material-icons.filter_drama:after,
.mi.filter_drama:after {
  content: '\e3dd';
}
.material-icons.filter_frames:after,
.mi.filter_frames:after {
  content: '\e3de';
}
.material-icons.filter_hdr:after,
.mi.filter_hdr:after {
  content: '\e3df';
}
.material-icons.filter_list:after,
.mi.filter_list:after {
  content: '\e152';
}
.material-icons.filter_none:after,
.mi.filter_none:after {
  content: '\e3e0';
}
.material-icons.filter_tilt_shift:after,
.mi.filter_tilt_shift:after {
  content: '\e3e2';
}
.material-icons.filter_vintage:after,
.mi.filter_vintage:after {
  content: '\e3e3';
}
.material-icons.find_in_page:after,
.mi.find_in_page:after {
  content: '\e880';
}
.material-icons.find_replace:after,
.mi.find_replace:after {
  content: '\e881';
}
.material-icons.fingerprint:after,
.mi.fingerprint:after {
  content: '\e90d';
}
.material-icons.first_page:after,
.mi.first_page:after {
  content: '\e5dc';
}
.material-icons.fitness_center:after,
.mi.fitness_center:after {
  content: '\eb43';
}
.material-icons.flag:after,
.mi.flag:after {
  content: '\e153';
}
.material-icons.flare:after,
.mi.flare:after {
  content: '\e3e4';
}
.material-icons.flash_auto:after,
.mi.flash_auto:after {
  content: '\e3e5';
}
.material-icons.flash_off:after,
.mi.flash_off:after {
  content: '\e3e6';
}
.material-icons.flash_on:after,
.mi.flash_on:after {
  content: '\e3e7';
}
.material-icons.flight:after,
.mi.flight:after {
  content: '\e539';
}
.material-icons.flight_land:after,
.mi.flight_land:after {
  content: '\e904';
}
.material-icons.flight_takeoff:after,
.mi.flight_takeoff:after {
  content: '\e905';
}
.material-icons.flip:after,
.mi.flip:after {
  content: '\e3e8';
}
.material-icons.flip_to_back:after,
.mi.flip_to_back:after {
  content: '\e882';
}
.material-icons.flip_to_front:after,
.mi.flip_to_front:after {
  content: '\e883';
}
.material-icons.folder:after,
.mi.folder:after {
  content: '\e2c7';
}
.material-icons.folder_open:after,
.mi.folder_open:after {
  content: '\e2c8';
}
.material-icons.folder_shared:after,
.mi.folder_shared:after {
  content: '\e2c9';
}
.material-icons.folder_special:after,
.mi.folder_special:after {
  content: '\e617';
}
.material-icons.font_download:after,
.mi.font_download:after {
  content: '\e167';
}
.material-icons.format_align_center:after,
.mi.format_align_center:after {
  content: '\e234';
}
.material-icons.format_align_justify:after,
.mi.format_align_justify:after {
  content: '\e235';
}
.material-icons.format_align_left:after,
.mi.format_align_left:after {
  content: '\e236';
}
.material-icons.format_align_right:after,
.mi.format_align_right:after {
  content: '\e237';
}
.material-icons.format_bold:after,
.mi.format_bold:after {
  content: '\e238';
}
.material-icons.format_clear:after,
.mi.format_clear:after {
  content: '\e239';
}
.material-icons.format_color_fill:after,
.mi.format_color_fill:after {
  content: '\e23a';
}
.material-icons.format_color_reset:after,
.mi.format_color_reset:after {
  content: '\e23b';
}
.material-icons.format_color_text:after,
.mi.format_color_text:after {
  content: '\e23c';
}
.material-icons.format_indent_decrease:after,
.mi.format_indent_decrease:after {
  content: '\e23d';
}
.material-icons.format_indent_increase:after,
.mi.format_indent_increase:after {
  content: '\e23e';
}
.material-icons.format_italic:after,
.mi.format_italic:after {
  content: '\e23f';
}
.material-icons.format_line_spacing:after,
.mi.format_line_spacing:after {
  content: '\e240';
}
.material-icons.format_list_bulleted:after,
.mi.format_list_bulleted:after {
  content: '\e241';
}
.material-icons.format_list_numbered:after,
.mi.format_list_numbered:after {
  content: '\e242';
}
.material-icons.format_paint:after,
.mi.format_paint:after {
  content: '\e243';
}
.material-icons.format_quote:after,
.mi.format_quote:after {
  content: '\e244';
}
.material-icons.format_shapes:after,
.mi.format_shapes:after {
  content: '\e25e';
}
.material-icons.format_size:after,
.mi.format_size:after {
  content: '\e245';
}
.material-icons.format_strikethrough:after,
.mi.format_strikethrough:after {
  content: '\e246';
}
.material-icons.format_textdirection_l_to_r:after,
.mi.format_textdirection_l_to_r:after {
  content: '\e247';
}
.material-icons.format_textdirection_r_to_l:after,
.mi.format_textdirection_r_to_l:after {
  content: '\e248';
}
.material-icons.format_underlined:after,
.mi.format_underlined:after {
  content: '\e249';
}
.material-icons.forum:after,
.mi.forum:after {
  content: '\e0bf';
}
.material-icons.forward:after,
.mi.forward:after {
  content: '\e154';
}
.material-icons.forward_10:after,
.mi.forward_10:after {
  content: '\e056';
}
.material-icons.forward_30:after,
.mi.forward_30:after {
  content: '\e057';
}
.material-icons.forward_5:after,
.mi.forward_5:after {
  content: '\e058';
}
.material-icons.free_breakfast:after,
.mi.free_breakfast:after {
  content: '\eb44';
}
.material-icons.fullscreen:after,
.mi.fullscreen:after {
  content: '\e5d0';
}
.material-icons.fullscreen_exit:after,
.mi.fullscreen_exit:after {
  content: '\e5d1';
}
.material-icons.functions:after,
.mi.functions:after {
  content: '\e24a';
}
.material-icons.g_translate:after,
.mi.g_translate:after {
  content: '\e927';
}
.material-icons.gamepad:after,
.mi.gamepad:after {
  content: '\e30f';
}
.material-icons.games:after,
.mi.games:after {
  content: '\e021';
}
.material-icons.gavel:after,
.mi.gavel:after {
  content: '\e90e';
}
.material-icons.gesture:after,
.mi.gesture:after {
  content: '\e155';
}
.material-icons.get_app:after,
.mi.get_app:after {
  content: '\e884';
}
.material-icons.gif:after,
.mi.gif:after {
  content: '\e908';
}
.material-icons.golf_course:after,
.mi.golf_course:after {
  content: '\eb45';
}
.material-icons.gps_fixed:after,
.mi.gps_fixed:after {
  content: '\e1b3';
}
.material-icons.gps_not_fixed:after,
.mi.gps_not_fixed:after {
  content: '\e1b4';
}
.material-icons.gps_off:after,
.mi.gps_off:after {
  content: '\e1b5';
}
.material-icons.grade:after,
.mi.grade:after {
  content: '\e885';
}
.material-icons.gradient:after,
.mi.gradient:after {
  content: '\e3e9';
}
.material-icons.grain:after,
.mi.grain:after {
  content: '\e3ea';
}
.material-icons.graphic_eq:after,
.mi.graphic_eq:after {
  content: '\e1b8';
}
.material-icons.grid_off:after,
.mi.grid_off:after {
  content: '\e3eb';
}
.material-icons.grid_on:after,
.mi.grid_on:after {
  content: '\e3ec';
}
.material-icons.group:after,
.mi.group:after {
  content: '\e7ef';
}
.material-icons.group_add:after,
.mi.group_add:after {
  content: '\e7f0';
}
.material-icons.group_work:after,
.mi.group_work:after {
  content: '\e886';
}
.material-icons.hd:after,
.mi.hd:after {
  content: '\e052';
}
.material-icons.hdr_off:after,
.mi.hdr_off:after {
  content: '\e3ed';
}
.material-icons.hdr_on:after,
.mi.hdr_on:after {
  content: '\e3ee';
}
.material-icons.hdr_strong:after,
.mi.hdr_strong:after {
  content: '\e3f1';
}
.material-icons.hdr_weak:after,
.mi.hdr_weak:after {
  content: '\e3f2';
}
.material-icons.headset:after,
.mi.headset:after {
  content: '\e310';
}
.material-icons.headset_mic:after,
.mi.headset_mic:after {
  content: '\e311';
}
.material-icons.healing:after,
.mi.healing:after {
  content: '\e3f3';
}
.material-icons.hearing:after,
.mi.hearing:after {
  content: '\e023';
}
.material-icons.help:after,
.mi.help:after {
  content: '\e887';
}
.material-icons.help_outline:after,
.mi.help_outline:after {
  content: '\e8fd';
}
.material-icons.high_quality:after,
.mi.high_quality:after {
  content: '\e024';
}
.material-icons.highlight:after,
.mi.highlight:after {
  content: '\e25f';
}
.material-icons.highlight_off:after,
.mi.highlight_off:after {
  content: '\e888';
}
.material-icons.history:after,
.mi.history:after {
  content: '\e889';
}
.material-icons.home:after,
.mi.home:after {
  content: '\e88a';
}
.material-icons.hot_tub:after,
.mi.hot_tub:after {
  content: '\eb46';
}
.material-icons.hotel:after,
.mi.hotel:after {
  content: '\e53a';
}
.material-icons.hourglass_empty:after,
.mi.hourglass_empty:after {
  content: '\e88b';
}
.material-icons.hourglass_full:after,
.mi.hourglass_full:after {
  content: '\e88c';
}
.material-icons.http:after,
.mi.http:after {
  content: '\e902';
}
.material-icons.https:after,
.mi.https:after {
  content: '\e88d';
}
.material-icons.image:after,
.mi.image:after {
  content: '\e3f4';
}
.material-icons.image_aspect_ratio:after,
.mi.image_aspect_ratio:after {
  content: '\e3f5';
}
.material-icons.import_contacts:after,
.mi.import_contacts:after {
  content: '\e0e0';
}
.material-icons.import_export:after,
.mi.import_export:after {
  content: '\e0c3';
}
.material-icons.important_devices:after,
.mi.important_devices:after {
  content: '\e912';
}
.material-icons.inbox:after,
.mi.inbox:after {
  content: '\e156';
}
.material-icons.indeterminate_check_box:after,
.mi.indeterminate_check_box:after {
  content: '\e909';
}
.material-icons.info:after,
.mi.info:after {
  content: '\e88e';
}
.material-icons.info_outline:after,
.mi.info_outline:after {
  content: '\e88f';
}
.material-icons.input:after,
.mi.input:after {
  content: '\e890';
}
.material-icons.insert_chart:after,
.mi.insert_chart:after {
  content: '\e24b';
}
.material-icons.insert_comment:after,
.mi.insert_comment:after {
  content: '\e24c';
}
.material-icons.insert_drive_file:after,
.mi.insert_drive_file:after {
  content: '\e24d';
}
.material-icons.insert_emoticon:after,
.mi.insert_emoticon:after {
  content: '\e24e';
}
.material-icons.insert_invitation:after,
.mi.insert_invitation:after {
  content: '\e24f';
}
.material-icons.insert_link:after,
.mi.insert_link:after {
  content: '\e250';
}
.material-icons.insert_photo:after,
.mi.insert_photo:after {
  content: '\e251';
}
.material-icons.invert_colors:after,
.mi.invert_colors:after {
  content: '\e891';
}
.material-icons.invert_colors_off:after,
.mi.invert_colors_off:after {
  content: '\e0c4';
}
.material-icons.iso:after,
.mi.iso:after {
  content: '\e3f6';
}
.material-icons.keyboard:after,
.mi.keyboard:after {
  content: '\e312';
}
.material-icons.keyboard_arrow_down:after,
.mi.keyboard_arrow_down:after {
  content: '\e313';
}
.material-icons.keyboard_arrow_left:after,
.mi.keyboard_arrow_left:after {
  content: '\e314';
}
.material-icons.keyboard_arrow_right:after,
.mi.keyboard_arrow_right:after {
  content: '\e315';
}
.material-icons.keyboard_arrow_up:after,
.mi.keyboard_arrow_up:after {
  content: '\e316';
}
.material-icons.keyboard_backspace:after,
.mi.keyboard_backspace:after {
  content: '\e317';
}
.material-icons.keyboard_capslock:after,
.mi.keyboard_capslock:after {
  content: '\e318';
}
.material-icons.keyboard_hide:after,
.mi.keyboard_hide:after {
  content: '\e31a';
}
.material-icons.keyboard_return:after,
.mi.keyboard_return:after {
  content: '\e31b';
}
.material-icons.keyboard_tab:after,
.mi.keyboard_tab:after {
  content: '\e31c';
}
.material-icons.keyboard_voice:after,
.mi.keyboard_voice:after {
  content: '\e31d';
}
.material-icons.kitchen:after,
.mi.kitchen:after {
  content: '\eb47';
}
.material-icons.label:after,
.mi.label:after {
  content: '\e892';
}
.material-icons.label_outline:after,
.mi.label_outline:after {
  content: '\e893';
}
.material-icons.landscape:after,
.mi.landscape:after {
  content: '\e3f7';
}
.material-icons.language:after,
.mi.language:after {
  content: '\e894';
}
.material-icons.laptop:after,
.mi.laptop:after {
  content: '\e31e';
}
.material-icons.laptop_chromebook:after,
.mi.laptop_chromebook:after {
  content: '\e31f';
}
.material-icons.laptop_mac:after,
.mi.laptop_mac:after {
  content: '\e320';
}
.material-icons.laptop_windows:after,
.mi.laptop_windows:after {
  content: '\e321';
}
.material-icons.last_page:after,
.mi.last_page:after {
  content: '\e5dd';
}
.material-icons.launch:after,
.mi.launch:after {
  content: '\e895';
}
.material-icons.layers:after,
.mi.layers:after {
  content: '\e53b';
}
.material-icons.layers_clear:after,
.mi.layers_clear:after {
  content: '\e53c';
}
.material-icons.leak_add:after,
.mi.leak_add:after {
  content: '\e3f8';
}
.material-icons.leak_remove:after,
.mi.leak_remove:after {
  content: '\e3f9';
}
.material-icons.lens:after,
.mi.lens:after {
  content: '\e3fa';
}
.material-icons.library_add:after,
.mi.library_add:after {
  content: '\e02e';
}
.material-icons.library_books:after,
.mi.library_books:after {
  content: '\e02f';
}
.material-icons.library_music:after,
.mi.library_music:after {
  content: '\e030';
}
.material-icons.lightbulb_outline:after,
.mi.lightbulb_outline:after {
  content: '\e90f';
}
.material-icons.line_style:after,
.mi.line_style:after {
  content: '\e919';
}
.material-icons.line_weight:after,
.mi.line_weight:after {
  content: '\e91a';
}
.material-icons.linear_scale:after,
.mi.linear_scale:after {
  content: '\e260';
}
.material-icons.link:after,
.mi.link:after {
  content: '\e157';
}
.material-icons.linked_camera:after,
.mi.linked_camera:after {
  content: '\e438';
}
.material-icons.list:after,
.mi.list:after {
  content: '\e896';
}
.material-icons.live_help:after,
.mi.live_help:after {
  content: '\e0c6';
}
.material-icons.live_tv:after,
.mi.live_tv:after {
  content: '\e639';
}
.material-icons.local_activity:after,
.mi.local_activity:after {
  content: '\e53f';
}
.material-icons.local_airport:after,
.mi.local_airport:after {
  content: '\e53d';
}
.material-icons.local_atm:after,
.mi.local_atm:after {
  content: '\e53e';
}
.material-icons.local_bar:after,
.mi.local_bar:after {
  content: '\e540';
}
.material-icons.local_cafe:after,
.mi.local_cafe:after {
  content: '\e541';
}
.material-icons.local_car_wash:after,
.mi.local_car_wash:after {
  content: '\e542';
}
.material-icons.local_convenience_store:after,
.mi.local_convenience_store:after {
  content: '\e543';
}
.material-icons.local_dining:after,
.mi.local_dining:after {
  content: '\e556';
}
.material-icons.local_drink:after,
.mi.local_drink:after {
  content: '\e544';
}
.material-icons.local_florist:after,
.mi.local_florist:after {
  content: '\e545';
}
.material-icons.local_gas_station:after,
.mi.local_gas_station:after {
  content: '\e546';
}
.material-icons.local_grocery_store:after,
.mi.local_grocery_store:after {
  content: '\e547';
}
.material-icons.local_hospital:after,
.mi.local_hospital:after {
  content: '\e548';
}
.material-icons.local_hotel:after,
.mi.local_hotel:after {
  content: '\e549';
}
.material-icons.local_laundry_service:after,
.mi.local_laundry_service:after {
  content: '\e54a';
}
.material-icons.local_library:after,
.mi.local_library:after {
  content: '\e54b';
}
.material-icons.local_mall:after,
.mi.local_mall:after {
  content: '\e54c';
}
.material-icons.local_movies:after,
.mi.local_movies:after {
  content: '\e54d';
}
.material-icons.local_offer:after,
.mi.local_offer:after {
  content: '\e54e';
}
.material-icons.local_parking:after,
.mi.local_parking:after {
  content: '\e54f';
}
.material-icons.local_pharmacy:after,
.mi.local_pharmacy:after {
  content: '\e550';
}
.material-icons.local_phone:after,
.mi.local_phone:after {
  content: '\e551';
}
.material-icons.local_pizza:after,
.mi.local_pizza:after {
  content: '\e552';
}
.material-icons.local_play:after,
.mi.local_play:after {
  content: '\e553';
}
.material-icons.local_post_office:after,
.mi.local_post_office:after {
  content: '\e554';
}
.material-icons.local_printshop:after,
.mi.local_printshop:after {
  content: '\e555';
}
.material-icons.local_see:after,
.mi.local_see:after {
  content: '\e557';
}
.material-icons.local_shipping:after,
.mi.local_shipping:after {
  content: '\e558';
}
.material-icons.local_taxi:after,
.mi.local_taxi:after {
  content: '\e559';
}
.material-icons.location_city:after,
.mi.location_city:after {
  content: '\e7f1';
}
.material-icons.location_disabled:after,
.mi.location_disabled:after {
  content: '\e1b6';
}
.material-icons.location_off:after,
.mi.location_off:after {
  content: '\e0c7';
}
.material-icons.location_on:after,
.mi.location_on:after {
  content: '\e0c8';
}
.material-icons.location_searching:after,
.mi.location_searching:after {
  content: '\e1b7';
}
.material-icons.lock:after,
.mi.lock:after {
  content: '\e897';
}
.material-icons.lock_open:after,
.mi.lock_open:after {
  content: '\e898';
}
.material-icons.lock_outline:after,
.mi.lock_outline:after {
  content: '\e899';
}
.material-icons.looks:after,
.mi.looks:after {
  content: '\e3fc';
}
.material-icons.looks_3:after,
.mi.looks_3:after {
  content: '\e3fb';
}
.material-icons.looks_4:after,
.mi.looks_4:after {
  content: '\e3fd';
}
.material-icons.looks_5:after,
.mi.looks_5:after {
  content: '\e3fe';
}
.material-icons.looks_6:after,
.mi.looks_6:after {
  content: '\e3ff';
}
.material-icons.looks_one:after,
.mi.looks_one:after {
  content: '\e400';
}
.material-icons.looks_two:after,
.mi.looks_two:after {
  content: '\e401';
}
.material-icons.loop:after,
.mi.loop:after {
  content: '\e028';
}
.material-icons.loupe:after,
.mi.loupe:after {
  content: '\e402';
}
.material-icons.low_priority:after,
.mi.low_priority:after {
  content: '\e16d';
}
.material-icons.loyalty:after,
.mi.loyalty:after {
  content: '\e89a';
}
.material-icons.mail:after,
.mi.mail:after {
  content: '\e158';
}
.material-icons.mail_outline:after,
.mi.mail_outline:after {
  content: '\e0e1';
}
.material-icons.map:after,
.mi.map:after {
  content: '\e55b';
}
.material-icons.markunread:after,
.mi.markunread:after {
  content: '\e159';
}
.material-icons.markunread_mailbox:after,
.mi.markunread_mailbox:after {
  content: '\e89b';
}
.material-icons.memory:after,
.mi.memory:after {
  content: '\e322';
}
.material-icons.menu:after,
.mi.menu:after {
  content: '\e5d2';
}
.material-icons.merge_type:after,
.mi.merge_type:after {
  content: '\e252';
}
.material-icons.message:after,
.mi.message:after {
  content: '\e0c9';
}
.material-icons.mic:after,
.mi.mic:after {
  content: '\e029';
}
.material-icons.mic_none:after,
.mi.mic_none:after {
  content: '\e02a';
}
.material-icons.mic_off:after,
.mi.mic_off:after {
  content: '\e02b';
}
.material-icons.mms:after,
.mi.mms:after {
  content: '\e618';
}
.material-icons.mode_comment:after,
.mi.mode_comment:after {
  content: '\e253';
}
.material-icons.mode_edit:after,
.mi.mode_edit:after {
  content: '\e254';
}
.material-icons.monetization_on:after,
.mi.monetization_on:after {
  content: '\e263';
}
.material-icons.money_off:after,
.mi.money_off:after {
  content: '\e25c';
}
.material-icons.monochrome_photos:after,
.mi.monochrome_photos:after {
  content: '\e403';
}
.material-icons.mood:after,
.mi.mood:after {
  content: '\e7f2';
}
.material-icons.mood_bad:after,
.mi.mood_bad:after {
  content: '\e7f3';
}
.material-icons.more:after,
.mi.more:after {
  content: '\e619';
}
.material-icons.more_horiz:after,
.mi.more_horiz:after {
  content: '\e5d3';
}
.material-icons.more_vert:after,
.mi.more_vert:after {
  content: '\e5d4';
}
.material-icons.motorcycle:after,
.mi.motorcycle:after {
  content: '\e91b';
}
.material-icons.mouse:after,
.mi.mouse:after {
  content: '\e323';
}
.material-icons.move_to_inbox:after,
.mi.move_to_inbox:after {
  content: '\e168';
}
.material-icons.movie:after,
.mi.movie:after {
  content: '\e02c';
}
.material-icons.movie_creation:after,
.mi.movie_creation:after {
  content: '\e404';
}
.material-icons.movie_filter:after,
.mi.movie_filter:after {
  content: '\e43a';
}
.material-icons.multiline_chart:after,
.mi.multiline_chart:after {
  content: '\e6df';
}
.material-icons.music_note:after,
.mi.music_note:after {
  content: '\e405';
}
.material-icons.music_video:after,
.mi.music_video:after {
  content: '\e063';
}
.material-icons.my_location:after,
.mi.my_location:after {
  content: '\e55c';
}
.material-icons.nature:after,
.mi.nature:after {
  content: '\e406';
}
.material-icons.nature_people:after,
.mi.nature_people:after {
  content: '\e407';
}
.material-icons.navigate_before:after,
.mi.navigate_before:after {
  content: '\e408';
}
.material-icons.navigate_next:after,
.mi.navigate_next:after {
  content: '\e409';
}
.material-icons.navigation:after,
.mi.navigation:after {
  content: '\e55d';
}
.material-icons.near_me:after,
.mi.near_me:after {
  content: '\e569';
}
.material-icons.network_cell:after,
.mi.network_cell:after {
  content: '\e1b9';
}
.material-icons.network_check:after,
.mi.network_check:after {
  content: '\e640';
}
.material-icons.network_locked:after,
.mi.network_locked:after {
  content: '\e61a';
}
.material-icons.network_wifi:after,
.mi.network_wifi:after {
  content: '\e1ba';
}
.material-icons.new_releases:after,
.mi.new_releases:after {
  content: '\e031';
}
.material-icons.next_week:after,
.mi.next_week:after {
  content: '\e16a';
}
.material-icons.nfc:after,
.mi.nfc:after {
  content: '\e1bb';
}
.material-icons.no_encryption:after,
.mi.no_encryption:after {
  content: '\e641';
}
.material-icons.no_sim:after,
.mi.no_sim:after {
  content: '\e0cc';
}
.material-icons.not_interested:after,
.mi.not_interested:after {
  content: '\e033';
}
.material-icons.note:after,
.mi.note:after {
  content: '\e06f';
}
.material-icons.note_add:after,
.mi.note_add:after {
  content: '\e89c';
}
.material-icons.notifications:after,
.mi.notifications:after {
  content: '\e7f4';
}
.material-icons.notifications_active:after,
.mi.notifications_active:after {
  content: '\e7f7';
}
.material-icons.notifications_none:after,
.mi.notifications_none:after {
  content: '\e7f5';
}
.material-icons.notifications_off:after,
.mi.notifications_off:after {
  content: '\e7f6';
}
.material-icons.notifications_paused:after,
.mi.notifications_paused:after {
  content: '\e7f8';
}
.material-icons.offline_pin:after,
.mi.offline_pin:after {
  content: '\e90a';
}
.material-icons.ondemand_video:after,
.mi.ondemand_video:after {
  content: '\e63a';
}
.material-icons.opacity:after,
.mi.opacity:after {
  content: '\e91c';
}
.material-icons.open_in_browser:after,
.mi.open_in_browser:after {
  content: '\e89d';
}
.material-icons.open_in_new:after,
.mi.open_in_new:after {
  content: '\e89e';
}
.material-icons.open_with:after,
.mi.open_with:after {
  content: '\e89f';
}
.material-icons.pages:after,
.mi.pages:after {
  content: '\e7f9';
}
.material-icons.pageview:after,
.mi.pageview:after {
  content: '\e8a0';
}
.material-icons.palette:after,
.mi.palette:after {
  content: '\e40a';
}
.material-icons.pan_tool:after,
.mi.pan_tool:after {
  content: '\e925';
}
.material-icons.panorama:after,
.mi.panorama:after {
  content: '\e40b';
}
.material-icons.panorama_fish_eye:after,
.mi.panorama_fish_eye:after {
  content: '\e40c';
}
.material-icons.panorama_horizontal:after,
.mi.panorama_horizontal:after {
  content: '\e40d';
}
.material-icons.panorama_vertical:after,
.mi.panorama_vertical:after {
  content: '\e40e';
}
.material-icons.panorama_wide_angle:after,
.mi.panorama_wide_angle:after {
  content: '\e40f';
}
.material-icons.party_mode:after,
.mi.party_mode:after {
  content: '\e7fa';
}
.material-icons.pause:after,
.mi.pause:after {
  content: '\e034';
}
.material-icons.pause_circle_filled:after,
.mi.pause_circle_filled:after {
  content: '\e035';
}
.material-icons.pause_circle_outline:after,
.mi.pause_circle_outline:after {
  content: '\e036';
}
.material-icons.payment:after,
.mi.payment:after {
  content: '\e8a1';
}
.material-icons.people:after,
.mi.people:after {
  content: '\e7fb';
}
.material-icons.people_outline:after,
.mi.people_outline:after {
  content: '\e7fc';
}
.material-icons.perm_camera_mic:after,
.mi.perm_camera_mic:after {
  content: '\e8a2';
}
.material-icons.perm_contact_calendar:after,
.mi.perm_contact_calendar:after {
  content: '\e8a3';
}
.material-icons.perm_data_setting:after,
.mi.perm_data_setting:after {
  content: '\e8a4';
}
.material-icons.perm_device_information:after,
.mi.perm_device_information:after {
  content: '\e8a5';
}
.material-icons.perm_identity:after,
.mi.perm_identity:after {
  content: '\e8a6';
}
.material-icons.perm_media:after,
.mi.perm_media:after {
  content: '\e8a7';
}
.material-icons.perm_phone_msg:after,
.mi.perm_phone_msg:after {
  content: '\e8a8';
}
.material-icons.perm_scan_wifi:after,
.mi.perm_scan_wifi:after {
  content: '\e8a9';
}
.material-icons.person:after,
.mi.person:after {
  content: '\e7fd';
}
.material-icons.person_add:after,
.mi.person_add:after {
  content: '\e7fe';
}
.material-icons.person_outline:after,
.mi.person_outline:after {
  content: '\e7ff';
}
.material-icons.person_pin:after,
.mi.person_pin:after {
  content: '\e55a';
}
.material-icons.person_pin_circle:after,
.mi.person_pin_circle:after {
  content: '\e56a';
}
.material-icons.personal_video:after,
.mi.personal_video:after {
  content: '\e63b';
}
.material-icons.pets:after,
.mi.pets:after {
  content: '\e91d';
}
.material-icons.phone:after,
.mi.phone:after {
  content: '\e0cd';
}
.material-icons.phone_android:after,
.mi.phone_android:after {
  content: '\e324';
}
.material-icons.phone_bluetooth_speaker:after,
.mi.phone_bluetooth_speaker:after {
  content: '\e61b';
}
.material-icons.phone_forwarded:after,
.mi.phone_forwarded:after {
  content: '\e61c';
}
.material-icons.phone_in_talk:after,
.mi.phone_in_talk:after {
  content: '\e61d';
}
.material-icons.phone_iphone:after,
.mi.phone_iphone:after {
  content: '\e325';
}
.material-icons.phone_locked:after,
.mi.phone_locked:after {
  content: '\e61e';
}
.material-icons.phone_missed:after,
.mi.phone_missed:after {
  content: '\e61f';
}
.material-icons.phone_paused:after,
.mi.phone_paused:after {
  content: '\e620';
}
.material-icons.phonelink:after,
.mi.phonelink:after {
  content: '\e326';
}
.material-icons.phonelink_erase:after,
.mi.phonelink_erase:after {
  content: '\e0db';
}
.material-icons.phonelink_lock:after,
.mi.phonelink_lock:after {
  content: '\e0dc';
}
.material-icons.phonelink_off:after,
.mi.phonelink_off:after {
  content: '\e327';
}
.material-icons.phonelink_ring:after,
.mi.phonelink_ring:after {
  content: '\e0dd';
}
.material-icons.phonelink_setup:after,
.mi.phonelink_setup:after {
  content: '\e0de';
}
.material-icons.photo:after,
.mi.photo:after {
  content: '\e410';
}
.material-icons.photo_album:after,
.mi.photo_album:after {
  content: '\e411';
}
.material-icons.photo_camera:after,
.mi.photo_camera:after {
  content: '\e412';
}
.material-icons.photo_filter:after,
.mi.photo_filter:after {
  content: '\e43b';
}
.material-icons.photo_library:after,
.mi.photo_library:after {
  content: '\e413';
}
.material-icons.photo_size_select_actual:after,
.mi.photo_size_select_actual:after {
  content: '\e432';
}
.material-icons.photo_size_select_large:after,
.mi.photo_size_select_large:after {
  content: '\e433';
}
.material-icons.photo_size_select_small:after,
.mi.photo_size_select_small:after {
  content: '\e434';
}
.material-icons.picture_as_pdf:after,
.mi.picture_as_pdf:after {
  content: '\e415';
}
.material-icons.picture_in_picture:after,
.mi.picture_in_picture:after {
  content: '\e8aa';
}
.material-icons.picture_in_picture_alt:after,
.mi.picture_in_picture_alt:after {
  content: '\e911';
}
.material-icons.pie_chart:after,
.mi.pie_chart:after {
  content: '\e6c4';
}
.material-icons.pie_chart_outlined:after,
.mi.pie_chart_outlined:after {
  content: '\e6c5';
}
.material-icons.pin_drop:after,
.mi.pin_drop:after {
  content: '\e55e';
}
.material-icons.place:after,
.mi.place:after {
  content: '\e55f';
}
.material-icons.play_arrow:after,
.mi.play_arrow:after {
  content: '\e037';
}
.material-icons.play_circle_filled:after,
.mi.play_circle_filled:after {
  content: '\e038';
}
.material-icons.play_circle_outline:after,
.mi.play_circle_outline:after {
  content: '\e039';
}
.material-icons.play_for_work:after,
.mi.play_for_work:after {
  content: '\e906';
}
.material-icons.playlist_add:after,
.mi.playlist_add:after {
  content: '\e03b';
}
.material-icons.playlist_add_check:after,
.mi.playlist_add_check:after {
  content: '\e065';
}
.material-icons.playlist_play:after,
.mi.playlist_play:after {
  content: '\e05f';
}
.material-icons.plus_one:after,
.mi.plus_one:after {
  content: '\e800';
}
.material-icons.poll:after,
.mi.poll:after {
  content: '\e801';
}
.material-icons.polymer:after,
.mi.polymer:after {
  content: '\e8ab';
}
.material-icons.pool:after,
.mi.pool:after {
  content: '\eb48';
}
.material-icons.portable_wifi_off:after,
.mi.portable_wifi_off:after {
  content: '\e0ce';
}
.material-icons.portrait:after,
.mi.portrait:after {
  content: '\e416';
}
.material-icons.power:after,
.mi.power:after {
  content: '\e63c';
}
.material-icons.power_input:after,
.mi.power_input:after {
  content: '\e336';
}
.material-icons.power_settings_new:after,
.mi.power_settings_new:after {
  content: '\e8ac';
}
.material-icons.pregnant_woman:after,
.mi.pregnant_woman:after {
  content: '\e91e';
}
.material-icons.present_to_all:after,
.mi.present_to_all:after {
  content: '\e0df';
}
.material-icons.print:after,
.mi.print:after {
  content: '\e8ad';
}
.material-icons.priority_high:after,
.mi.priority_high:after {
  content: '\e645';
}
.material-icons.public:after,
.mi.public:after {
  content: '\e80b';
}
.material-icons.publish:after,
.mi.publish:after {
  content: '\e255';
}
.material-icons.query_builder:after,
.mi.query_builder:after {
  content: '\e8ae';
}
.material-icons.question_answer:after,
.mi.question_answer:after {
  content: '\e8af';
}
.material-icons.queue:after,
.mi.queue:after {
  content: '\e03c';
}
.material-icons.queue_music:after,
.mi.queue_music:after {
  content: '\e03d';
}
.material-icons.queue_play_next:after,
.mi.queue_play_next:after {
  content: '\e066';
}
.material-icons.radio:after,
.mi.radio:after {
  content: '\e03e';
}
.material-icons.radio_button_checked:after,
.mi.radio_button_checked:after {
  content: '\e837';
}
.material-icons.radio_button_unchecked:after,
.mi.radio_button_unchecked:after {
  content: '\e836';
}
.material-icons.rate_review:after,
.mi.rate_review:after {
  content: '\e560';
}
.material-icons.receipt:after,
.mi.receipt:after {
  content: '\e8b0';
}
.material-icons.recent_actors:after,
.mi.recent_actors:after {
  content: '\e03f';
}
.material-icons.record_voice_over:after,
.mi.record_voice_over:after {
  content: '\e91f';
}
.material-icons.redeem:after,
.mi.redeem:after {
  content: '\e8b1';
}
.material-icons.redo:after,
.mi.redo:after {
  content: '\e15a';
}
.material-icons.refresh:after,
.mi.refresh:after {
  content: '\e5d5';
}
.material-icons.remove:after,
.mi.remove:after {
  content: '\e15b';
}
.material-icons.remove_circle:after,
.mi.remove_circle:after {
  content: '\e15c';
}
.material-icons.remove_circle_outline:after,
.mi.remove_circle_outline:after {
  content: '\e15d';
}
.material-icons.remove_from_queue:after,
.mi.remove_from_queue:after {
  content: '\e067';
}
.material-icons.remove_red_eye:after,
.mi.remove_red_eye:after {
  content: '\e417';
}
.material-icons.remove_shopping_cart:after,
.mi.remove_shopping_cart:after {
  content: '\e928';
}
.material-icons.reorder:after,
.mi.reorder:after {
  content: '\e8fe';
}
.material-icons.repeat:after,
.mi.repeat:after {
  content: '\e040';
}
.material-icons.repeat_one:after,
.mi.repeat_one:after {
  content: '\e041';
}
.material-icons.replay:after,
.mi.replay:after {
  content: '\e042';
}
.material-icons.replay_10:after,
.mi.replay_10:after {
  content: '\e059';
}
.material-icons.replay_30:after,
.mi.replay_30:after {
  content: '\e05a';
}
.material-icons.replay_5:after,
.mi.replay_5:after {
  content: '\e05b';
}
.material-icons.reply:after,
.mi.reply:after {
  content: '\e15e';
}
.material-icons.reply_all:after,
.mi.reply_all:after {
  content: '\e15f';
}
.material-icons.report:after,
.mi.report:after {
  content: '\e160';
}
.material-icons.report_problem:after,
.mi.report_problem:after {
  content: '\e8b2';
}
.material-icons.restaurant:after,
.mi.restaurant:after {
  content: '\e56c';
}
.material-icons.restaurant_menu:after,
.mi.restaurant_menu:after {
  content: '\e561';
}
.material-icons.restore:after,
.mi.restore:after {
  content: '\e8b3';
}
.material-icons.restore_page:after,
.mi.restore_page:after {
  content: '\e929';
}
.material-icons.ring_volume:after,
.mi.ring_volume:after {
  content: '\e0d1';
}
.material-icons.room:after,
.mi.room:after {
  content: '\e8b4';
}
.material-icons.room_service:after,
.mi.room_service:after {
  content: '\eb49';
}
.material-icons.rotate_90_degrees_ccw:after,
.mi.rotate_90_degrees_ccw:after {
  content: '\e418';
}
.material-icons.rotate_left:after,
.mi.rotate_left:after {
  content: '\e419';
}
.material-icons.rotate_right:after,
.mi.rotate_right:after {
  content: '\e41a';
}
.material-icons.rounded_corner:after,
.mi.rounded_corner:after {
  content: '\e920';
}
.material-icons.router:after,
.mi.router:after {
  content: '\e328';
}
.material-icons.rowing:after,
.mi.rowing:after {
  content: '\e921';
}
.material-icons.rss_feed:after,
.mi.rss_feed:after {
  content: '\e0e5';
}
.material-icons.rv_hookup:after,
.mi.rv_hookup:after {
  content: '\e642';
}
.material-icons.satellite:after,
.mi.satellite:after {
  content: '\e562';
}
.material-icons.save:after,
.mi.save:after {
  content: '\e161';
}
.material-icons.scanner:after,
.mi.scanner:after {
  content: '\e329';
}
.material-icons.schedule:after,
.mi.schedule:after {
  content: '\e8b5';
}
.material-icons.school:after,
.mi.school:after {
  content: '\e80c';
}
.material-icons.screen_lock_landscape:after,
.mi.screen_lock_landscape:after {
  content: '\e1be';
}
.material-icons.screen_lock_portrait:after,
.mi.screen_lock_portrait:after {
  content: '\e1bf';
}
.material-icons.screen_lock_rotation:after,
.mi.screen_lock_rotation:after {
  content: '\e1c0';
}
.material-icons.screen_rotation:after,
.mi.screen_rotation:after {
  content: '\e1c1';
}
.material-icons.screen_share:after,
.mi.screen_share:after {
  content: '\e0e2';
}
.material-icons.sd_card:after,
.mi.sd_card:after {
  content: '\e623';
}
.material-icons.sd_storage:after,
.mi.sd_storage:after {
  content: '\e1c2';
}
.material-icons.search:after,
.mi.search:after {
  content: '\e8b6';
}
.material-icons.security:after,
.mi.security:after {
  content: '\e32a';
}
.material-icons.select_all:after,
.mi.select_all:after {
  content: '\e162';
}
.material-icons.send:after,
.mi.send:after {
  content: '\e163';
}
.material-icons.sentiment_dissatisfied:after,
.mi.sentiment_dissatisfied:after {
  content: '\e811';
}
.material-icons.sentiment_neutral:after,
.mi.sentiment_neutral:after {
  content: '\e812';
}
.material-icons.sentiment_satisfied:after,
.mi.sentiment_satisfied:after {
  content: '\e813';
}
.material-icons.sentiment_very_dissatisfied:after,
.mi.sentiment_very_dissatisfied:after {
  content: '\e814';
}
.material-icons.sentiment_very_satisfied:after,
.mi.sentiment_very_satisfied:after {
  content: '\e815';
}
.material-icons.settings:after,
.mi.settings:after {
  content: '\e8b8';
}
.material-icons.settings_applications:after,
.mi.settings_applications:after {
  content: '\e8b9';
}
.material-icons.settings_backup_restore:after,
.mi.settings_backup_restore:after {
  content: '\e8ba';
}
.material-icons.settings_bluetooth:after,
.mi.settings_bluetooth:after {
  content: '\e8bb';
}
.material-icons.settings_brightness:after,
.mi.settings_brightness:after {
  content: '\e8bd';
}
.material-icons.settings_cell:after,
.mi.settings_cell:after {
  content: '\e8bc';
}
.material-icons.settings_ethernet:after,
.mi.settings_ethernet:after {
  content: '\e8be';
}
.material-icons.settings_input_antenna:after,
.mi.settings_input_antenna:after {
  content: '\e8bf';
}
.material-icons.settings_input_component:after,
.mi.settings_input_component:after {
  content: '\e8c0';
}
.material-icons.settings_input_composite:after,
.mi.settings_input_composite:after {
  content: '\e8c1';
}
.material-icons.settings_input_hdmi:after,
.mi.settings_input_hdmi:after {
  content: '\e8c2';
}
.material-icons.settings_input_svideo:after,
.mi.settings_input_svideo:after {
  content: '\e8c3';
}
.material-icons.settings_overscan:after,
.mi.settings_overscan:after {
  content: '\e8c4';
}
.material-icons.settings_phone:after,
.mi.settings_phone:after {
  content: '\e8c5';
}
.material-icons.settings_power:after,
.mi.settings_power:after {
  content: '\e8c6';
}
.material-icons.settings_remote:after,
.mi.settings_remote:after {
  content: '\e8c7';
}
.material-icons.settings_system_daydream:after,
.mi.settings_system_daydream:after {
  content: '\e1c3';
}
.material-icons.settings_voice:after,
.mi.settings_voice:after {
  content: '\e8c8';
}
.material-icons.share:after,
.mi.share:after {
  content: '\e80d';
}
.material-icons.shop:after,
.mi.shop:after {
  content: '\e8c9';
}
.material-icons.shop_two:after,
.mi.shop_two:after {
  content: '\e8ca';
}
.material-icons.shopping_basket:after,
.mi.shopping_basket:after {
  content: '\e8cb';
}
.material-icons.shopping_cart:after,
.mi.shopping_cart:after {
  content: '\e8cc';
}
.material-icons.short_text:after,
.mi.short_text:after {
  content: '\e261';
}
.material-icons.show_chart:after,
.mi.show_chart:after {
  content: '\e6e1';
}
.material-icons.shuffle:after,
.mi.shuffle:after {
  content: '\e043';
}
.material-icons.signal_cellular_4_bar:after,
.mi.signal_cellular_4_bar:after {
  content: '\e1c8';
}
.material-icons.signal_cellular_connected_no_internet_4_bar:after,
.mi.signal_cellular_connected_no_internet_4_bar:after {
  content: '\e1cd';
}
.material-icons.signal_cellular_no_sim:after,
.mi.signal_cellular_no_sim:after {
  content: '\e1ce';
}
.material-icons.signal_cellular_null:after,
.mi.signal_cellular_null:after {
  content: '\e1cf';
}
.material-icons.signal_cellular_off:after,
.mi.signal_cellular_off:after {
  content: '\e1d0';
}
.material-icons.signal_wifi_4_bar:after,
.mi.signal_wifi_4_bar:after {
  content: '\e1d8';
}
.material-icons.signal_wifi_4_bar_lock:after,
.mi.signal_wifi_4_bar_lock:after {
  content: '\e1d9';
}
.material-icons.signal_wifi_off:after,
.mi.signal_wifi_off:after {
  content: '\e1da';
}
.material-icons.sim_card:after,
.mi.sim_card:after {
  content: '\e32b';
}
.material-icons.sim_card_alert:after,
.mi.sim_card_alert:after {
  content: '\e624';
}
.material-icons.skip_next:after,
.mi.skip_next:after {
  content: '\e044';
}
.material-icons.skip_previous:after,
.mi.skip_previous:after {
  content: '\e045';
}
.material-icons.slideshow:after,
.mi.slideshow:after {
  content: '\e41b';
}
.material-icons.slow_motion_video:after,
.mi.slow_motion_video:after {
  content: '\e068';
}
.material-icons.smartphone:after,
.mi.smartphone:after {
  content: '\e32c';
}
.material-icons.smoke_free:after,
.mi.smoke_free:after {
  content: '\eb4a';
}
.material-icons.smoking_rooms:after,
.mi.smoking_rooms:after {
  content: '\eb4b';
}
.material-icons.sms:after,
.mi.sms:after {
  content: '\e625';
}
.material-icons.sms_failed:after,
.mi.sms_failed:after {
  content: '\e626';
}
.material-icons.snooze:after,
.mi.snooze:after {
  content: '\e046';
}
.material-icons.sort:after,
.mi.sort:after {
  content: '\e164';
}
.material-icons.sort_by_alpha:after,
.mi.sort_by_alpha:after {
  content: '\e053';
}
.material-icons.spa:after,
.mi.spa:after {
  content: '\eb4c';
}
.material-icons.space_bar:after,
.mi.space_bar:after {
  content: '\e256';
}
.material-icons.speaker:after,
.mi.speaker:after {
  content: '\e32d';
}
.material-icons.speaker_group:after,
.mi.speaker_group:after {
  content: '\e32e';
}
.material-icons.speaker_notes:after,
.mi.speaker_notes:after {
  content: '\e8cd';
}
.material-icons.speaker_notes_off:after,
.mi.speaker_notes_off:after {
  content: '\e92a';
}
.material-icons.speaker_phone:after,
.mi.speaker_phone:after {
  content: '\e0d2';
}
.material-icons.spellcheck:after,
.mi.spellcheck:after {
  content: '\e8ce';
}
.material-icons.star:after,
.mi.star:after {
  content: '\e838';
}
.material-icons.star_border:after,
.mi.star_border:after {
  content: '\e83a';
}
.material-icons.star_half:after,
.mi.star_half:after {
  content: '\e839';
}
.material-icons.stars:after,
.mi.stars:after {
  content: '\e8d0';
}
.material-icons.stay_current_landscape:after,
.mi.stay_current_landscape:after {
  content: '\e0d3';
}
.material-icons.stay_current_portrait:after,
.mi.stay_current_portrait:after {
  content: '\e0d4';
}
.material-icons.stay_primary_landscape:after,
.mi.stay_primary_landscape:after {
  content: '\e0d5';
}
.material-icons.stay_primary_portrait:after,
.mi.stay_primary_portrait:after {
  content: '\e0d6';
}
.material-icons.stop:after,
.mi.stop:after {
  content: '\e047';
}
.material-icons.stop_screen_share:after,
.mi.stop_screen_share:after {
  content: '\e0e3';
}
.material-icons.storage:after,
.mi.storage:after {
  content: '\e1db';
}
.material-icons.store:after,
.mi.store:after {
  content: '\e8d1';
}
.material-icons.store_mall_directory:after,
.mi.store_mall_directory:after {
  content: '\e563';
}
.material-icons.straighten:after,
.mi.straighten:after {
  content: '\e41c';
}
.material-icons.streetview:after,
.mi.streetview:after {
  content: '\e56e';
}
.material-icons.strikethrough_s:after,
.mi.strikethrough_s:after {
  content: '\e257';
}
.material-icons.style:after,
.mi.style:after {
  content: '\e41d';
}
.material-icons.subdirectory_arrow_left:after,
.mi.subdirectory_arrow_left:after {
  content: '\e5d9';
}
.material-icons.subdirectory_arrow_right:after,
.mi.subdirectory_arrow_right:after {
  content: '\e5da';
}
.material-icons.subject:after,
.mi.subject:after {
  content: '\e8d2';
}
.material-icons.subscriptions:after,
.mi.subscriptions:after {
  content: '\e064';
}
.material-icons.subtitles:after,
.mi.subtitles:after {
  content: '\e048';
}
.material-icons.subway:after,
.mi.subway:after {
  content: '\e56f';
}
.material-icons.supervisor_account:after,
.mi.supervisor_account:after {
  content: '\e8d3';
}
.material-icons.surround_sound:after,
.mi.surround_sound:after {
  content: '\e049';
}
.material-icons.swap_calls:after,
.mi.swap_calls:after {
  content: '\e0d7';
}
.material-icons.swap_horiz:after,
.mi.swap_horiz:after {
  content: '\e8d4';
}
.material-icons.swap_vert:after,
.mi.swap_vert:after {
  content: '\e8d5';
}
.material-icons.swap_vertical_circle:after,
.mi.swap_vertical_circle:after {
  content: '\e8d6';
}
.material-icons.switch_camera:after,
.mi.switch_camera:after {
  content: '\e41e';
}
.material-icons.switch_video:after,
.mi.switch_video:after {
  content: '\e41f';
}
.material-icons.sync:after,
.mi.sync:after {
  content: '\e627';
}
.material-icons.sync_disabled:after,
.mi.sync_disabled:after {
  content: '\e628';
}
.material-icons.sync_problem:after,
.mi.sync_problem:after {
  content: '\e629';
}
.material-icons.system_update:after,
.mi.system_update:after {
  content: '\e62a';
}
.material-icons.system_update_alt:after,
.mi.system_update_alt:after {
  content: '\e8d7';
}
.material-icons.tab:after,
.mi.tab:after {
  content: '\e8d8';
}
.material-icons.tab_unselected:after,
.mi.tab_unselected:after {
  content: '\e8d9';
}
.material-icons.tablet:after,
.mi.tablet:after {
  content: '\e32f';
}
.material-icons.tablet_android:after,
.mi.tablet_android:after {
  content: '\e330';
}
.material-icons.tablet_mac:after,
.mi.tablet_mac:after {
  content: '\e331';
}
.material-icons.tag_faces:after,
.mi.tag_faces:after {
  content: '\e420';
}
.material-icons.tap_and_play:after,
.mi.tap_and_play:after {
  content: '\e62b';
}
.material-icons.terrain:after,
.mi.terrain:after {
  content: '\e564';
}
.material-icons.text_fields:after,
.mi.text_fields:after {
  content: '\e262';
}
.material-icons.text_format:after,
.mi.text_format:after {
  content: '\e165';
}
.material-icons.textsms:after,
.mi.textsms:after {
  content: '\e0d8';
}
.material-icons.texture:after,
.mi.texture:after {
  content: '\e421';
}
.material-icons.theaters:after,
.mi.theaters:after {
  content: '\e8da';
}
.material-icons.thumb_down:after,
.mi.thumb_down:after {
  content: '\e8db';
}
.material-icons.thumb_up:after,
.mi.thumb_up:after {
  content: '\e8dc';
}
.material-icons.thumbs_up_down:after,
.mi.thumbs_up_down:after {
  content: '\e8dd';
}
.material-icons.time_to_leave:after,
.mi.time_to_leave:after {
  content: '\e62c';
}
.material-icons.timelapse:after,
.mi.timelapse:after {
  content: '\e422';
}
.material-icons.timeline:after,
.mi.timeline:after {
  content: '\e922';
}
.material-icons.timer:after,
.mi.timer:after {
  content: '\e425';
}
.material-icons.timer_10:after,
.mi.timer_10:after {
  content: '\e423';
}
.material-icons.timer_3:after,
.mi.timer_3:after {
  content: '\e424';
}
.material-icons.timer_off:after,
.mi.timer_off:after {
  content: '\e426';
}
.material-icons.title:after,
.mi.title:after {
  content: '\e264';
}
.material-icons.toc:after,
.mi.toc:after {
  content: '\e8de';
}
.material-icons.today:after,
.mi.today:after {
  content: '\e8df';
}
.material-icons.toll:after,
.mi.toll:after {
  content: '\e8e0';
}
.material-icons.tonality:after,
.mi.tonality:after {
  content: '\e427';
}
.material-icons.touch_app:after,
.mi.touch_app:after {
  content: '\e913';
}
.material-icons.toys:after,
.mi.toys:after {
  content: '\e332';
}
.material-icons.track_changes:after,
.mi.track_changes:after {
  content: '\e8e1';
}
.material-icons.traffic:after,
.mi.traffic:after {
  content: '\e565';
}
.material-icons.train:after,
.mi.train:after {
  content: '\e570';
}
.material-icons.tram:after,
.mi.tram:after {
  content: '\e571';
}
.material-icons.transfer_within_a_station:after,
.mi.transfer_within_a_station:after {
  content: '\e572';
}
.material-icons.transform:after,
.mi.transform:after {
  content: '\e428';
}
.material-icons.translate:after,
.mi.translate:after {
  content: '\e8e2';
}
.material-icons.trending_down:after,
.mi.trending_down:after {
  content: '\e8e3';
}
.material-icons.trending_flat:after,
.mi.trending_flat:after {
  content: '\e8e4';
}
.material-icons.trending_up:after,
.mi.trending_up:after {
  content: '\e8e5';
}
.material-icons.tune:after,
.mi.tune:after {
  content: '\e429';
}
.material-icons.turned_in:after,
.mi.turned_in:after {
  content: '\e8e6';
}
.material-icons.turned_in_not:after,
.mi.turned_in_not:after {
  content: '\e8e7';
}
.material-icons.tv:after,
.mi.tv:after {
  content: '\e333';
}
.material-icons.unarchive:after,
.mi.unarchive:after {
  content: '\e169';
}
.material-icons.undo:after,
.mi.undo:after {
  content: '\e166';
}
.material-icons.unfold_less:after,
.mi.unfold_less:after {
  content: '\e5d6';
}
.material-icons.unfold_more:after,
.mi.unfold_more:after {
  content: '\e5d7';
}
.material-icons.update:after,
.mi.update:after {
  content: '\e923';
}
.material-icons.usb:after,
.mi.usb:after {
  content: '\e1e0';
}
.material-icons.verified_user:after,
.mi.verified_user:after {
  content: '\e8e8';
}
.material-icons.vertical_align_bottom:after,
.mi.vertical_align_bottom:after {
  content: '\e258';
}
.material-icons.vertical_align_center:after,
.mi.vertical_align_center:after {
  content: '\e259';
}
.material-icons.vertical_align_top:after,
.mi.vertical_align_top:after {
  content: '\e25a';
}
.material-icons.vibration:after,
.mi.vibration:after {
  content: '\e62d';
}
.material-icons.video_call:after,
.mi.video_call:after {
  content: '\e070';
}
.material-icons.video_label:after,
.mi.video_label:after {
  content: '\e071';
}
.material-icons.video_library:after,
.mi.video_library:after {
  content: '\e04a';
}
.material-icons.videocam:after,
.mi.videocam:after {
  content: '\e04b';
}
.material-icons.videocam_off:after,
.mi.videocam_off:after {
  content: '\e04c';
}
.material-icons.videogame_asset:after,
.mi.videogame_asset:after {
  content: '\e338';
}
.material-icons.view_agenda:after,
.mi.view_agenda:after {
  content: '\e8e9';
}
.material-icons.view_array:after,
.mi.view_array:after {
  content: '\e8ea';
}
.material-icons.view_carousel:after,
.mi.view_carousel:after {
  content: '\e8eb';
}
.material-icons.view_column:after,
.mi.view_column:after {
  content: '\e8ec';
}
.material-icons.view_comfy:after,
.mi.view_comfy:after {
  content: '\e42a';
}
.material-icons.view_compact:after,
.mi.view_compact:after {
  content: '\e42b';
}
.material-icons.view_day:after,
.mi.view_day:after {
  content: '\e8ed';
}
.material-icons.view_headline:after,
.mi.view_headline:after {
  content: '\e8ee';
}
.material-icons.view_list:after,
.mi.view_list:after {
  content: '\e8ef';
}
.material-icons.view_module:after,
.mi.view_module:after {
  content: '\e8f0';
}
.material-icons.view_quilt:after,
.mi.view_quilt:after {
  content: '\e8f1';
}
.material-icons.view_stream:after,
.mi.view_stream:after {
  content: '\e8f2';
}
.material-icons.view_week:after,
.mi.view_week:after {
  content: '\e8f3';
}
.material-icons.vignette:after,
.mi.vignette:after {
  content: '\e435';
}
.material-icons.visibility:after,
.mi.visibility:after {
  content: '\e8f4';
}
.material-icons.visibility_off:after,
.mi.visibility_off:after {
  content: '\e8f5';
}
.material-icons.voice_chat:after,
.mi.voice_chat:after {
  content: '\e62e';
}
.material-icons.voicemail:after,
.mi.voicemail:after {
  content: '\e0d9';
}
.material-icons.volume_down:after,
.mi.volume_down:after {
  content: '\e04d';
}
.material-icons.volume_mute:after,
.mi.volume_mute:after {
  content: '\e04e';
}
.material-icons.volume_off:after,
.mi.volume_off:after {
  content: '\e04f';
}
.material-icons.volume_up:after,
.mi.volume_up:after {
  content: '\e050';
}
.material-icons.vpn_key:after,
.mi.vpn_key:after {
  content: '\e0da';
}
.material-icons.vpn_lock:after,
.mi.vpn_lock:after {
  content: '\e62f';
}
.material-icons.wallpaper:after,
.mi.wallpaper:after {
  content: '\e1bc';
}
.material-icons.warning:after,
.mi.warning:after {
  content: '\e002';
}
.material-icons.watch:after,
.mi.watch:after {
  content: '\e334';
}
.material-icons.watch_later:after,
.mi.watch_later:after {
  content: '\e924';
}
.material-icons.wb_auto:after,
.mi.wb_auto:after {
  content: '\e42c';
}
.material-icons.wb_cloudy:after,
.mi.wb_cloudy:after {
  content: '\e42d';
}
.material-icons.wb_incandescent:after,
.mi.wb_incandescent:after {
  content: '\e42e';
}
.material-icons.wb_iridescent:after,
.mi.wb_iridescent:after {
  content: '\e436';
}
.material-icons.wb_sunny:after,
.mi.wb_sunny:after {
  content: '\e430';
}
.material-icons.wc:after,
.mi.wc:after {
  content: '\e63d';
}
.material-icons.web:after,
.mi.web:after {
  content: '\e051';
}
.material-icons.web_asset:after,
.mi.web_asset:after {
  content: '\e069';
}
.material-icons.weekend:after,
.mi.weekend:after {
  content: '\e16b';
}
.material-icons.whatshot:after,
.mi.whatshot:after {
  content: '\e80e';
}
.material-icons.widgets:after,
.mi.widgets:after {
  content: '\e1bd';
}
.material-icons.wifi:after,
.mi.wifi:after {
  content: '\e63e';
}
.material-icons.wifi_lock:after,
.mi.wifi_lock:after {
  content: '\e1e1';
}
.material-icons.wifi_tethering:after,
.mi.wifi_tethering:after {
  content: '\e1e2';
}
.material-icons.work:after,
.mi.work:after {
  content: '\e8f9';
}
.material-icons.wrap_text:after,
.mi.wrap_text:after {
  content: '\e25b';
}
.material-icons.youtube_searched_for:after,
.mi.youtube_searched_for:after {
  content: '\e8fa';
}
.material-icons.zoom_in:after,
.mi.zoom_in:after {
  content: '\e8ff';
}
.material-icons.zoom_out:after,
.mi.zoom_out:after {
  content: '\e900';
}
.material-icons.zoom_out_map:after,
.mi.zoom_out_map:after {
  content: '\e56b';
}